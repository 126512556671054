import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Tooltip,
} from '@mui/material';

import {
  Typography
} from '../styled';

import { MoveMoment as MM } from '../../../models/MoveMoment';

const { DateTime } = require("luxon");

type Props = {
  mmoment: MM;
  isCoach?: boolean;
  onClick?: () => void;
};

export const MoveMoment: FunctionComponent<Props> = ({mmoment, isCoach, onClick}) => {
  const theme = useTheme();
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);

  // const [actualType, setActualType] = useState<string>(type || 'text');

  let texts:(string|ReactElement)[] = [];
  if (!!mmoment.given_entry) texts.push(mmoment.given_entry);
  if (isCoach && !!mmoment.entry_time_actual) {
    // if (texts.length > 0) texts.push(<br />);
    texts.push(`(${DateTime.fromISO(mmoment.entry_time_actual).toFormat("cccc DDD HH:mm")})`);
  }
  if (!!mmoment.activity) {
    // if (texts.length > 0) texts.push(<br />);
    texts.push(mmoment.activity);
  }
  if (parseInt(mmoment.duration || '0', 10) > 0) {
    // if (texts.length > 0) texts.push(<br />);
    let dur = parseInt(mmoment.duration || '0', 10);
    let min = dur % 60;
    var hr = (dur - min) / 60;
    texts.push(`${hr}:${min < 10 ? '0' : ''}${min}`);
  }
  if (parseInt(mmoment.amount_steps || '0', 10) > 0) {
    // if (texts.length > 0) texts.push(<br />);
    texts.push(`${mmoment.amount_steps} stappen`);
  }
  if (parseFloat(mmoment.amount_km || '0') > 0) {
    // if (texts.length > 0) texts.push(<br />);
    texts.push(`${parseFloat(mmoment.amount_km || '0').toLocaleString(undefined, {maximumFractionDigits: 1})} km`);
  }
  (mmoment.feedbacks || []).forEach((c) => {
    if (!isCoach || (!!isCoach && c.practice_id === currentPractice?.id)) {
      texts.push(<Typography sx={{
        fontSize: "0.8rem",
        marginLeft: 2,
        color: theme.palette.primary.main
      }}>{c.username}: {c.comment}</Typography>);
    }
  });
  // let comment = move_moment.comment.filter((c) => c.attributes.user_id === this.props.login.id)[0] ? move_moment.comment.filter((c) => c.attributes.user_id === this.props.login.id)[0].attributes.comment : "";

  let tup = false;
  (mmoment.feedbacks || []).forEach((comm) => {
    if (!isCoach || (!!isCoach && comm.practice_id === currentPractice?.id)) {
      if (comm.thumb) tup = true;
    }
  });
 
  return (<Tooltip title={<Box sx={{padding: 1.5}}>{texts.map(tt => <Typography sx={{marginTop: 0.5, marginBottom: 0.5}}>{tt}</Typography>)}</Box>}>
    <Box
      sx={{
        border: `2px solid transparent`,
        borderColor: theme.palette.primary.main,
        borderRadius: 25,
        width: 30,
        height: 30,
        textAlign: 'center',
        fontSize: '1.1rem',
        margin: 0.5,
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative'
      }}
      onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}
    >
      <span className={`fa ${mmoment.fa_key}`} />
      {(mmoment.comments || []).filter(fff => (!isCoach || (!!isCoach && fff.practice_id === currentPractice?.id))).length > 0 && <span style={{
        position: 'absolute',
        top: -5,
        right: -5,
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        // "-webkit-text-stroke-width": '1px',
        // "-webkit-text-stroke-color": 'white'
      }} className={'fa fa-comment-dots'}></span>}
      {tup && <span style={{
        position: 'absolute',
        right: -5,
        bottom: -5,
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        // "-webkit-text-stroke-width": '1px',
        // "-webkit-text-stroke-color": 'white'
      }} className={'fa fa-thumbs-up'}></span>}
    </Box>
  </Tooltip>);
}