import React, { ElementType, FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';

import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';

import DeleteIcon from '@mui/icons-material/Delete';
import InboxIcon from '@mui/icons-material/Inbox';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Paper,
  Stack
} from '@mui/material';

import {
  Dt,
  Fab,
  TextInput,
  Typography
} from './styled';
import { NutriMail as DialogNutriMail } from './dialogs/NutriMail';
import { InboxNutriMail } from './NutriMail';

import { NutriMail } from '../../models/NutriMail';

type Props = {
  
};

export const Inbox: FunctionComponent<Props> = ({}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const {t, i18n} = useTranslation(['translations']);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [search, setSearch] = useState<string>('');
  const [currentTab, setCurrentTab] = useState<string>('inbox');
  const [page, setPage] = useState<number>(0);
  const [activeMail, setActiveMail] = useState<NutriMail>();
  let mailCount = 0;
  const [activeClient, setActiveClient] = useState<undefined|{id: number; name: string;}>({
    id: state?.user_id?.id,
    name: state?.user_id?.name
  });

  const {
    data: mails,
    isLoading: mailsLoading,
    isError: mailsError,
    isSuccess: mailsSuccess,
    refetch: mailsRefetch,
  } = useQuery({
    queryKey: ["nutri_mails", login?.id, "tab", currentTab, "page", page, {check_user: activeClient?.id, search: search}],
    queryFn: () =>
      fetch_all_with_count<NutriMail>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'nutri_mail',
          fields: [
            'id', 'to', 'from', 'read', 'replied', 'sent', 'is_draft', 'deleted', 'created_at', 'subject', 'body',
            'nutri_mail_attachments|count', 'nutri_mail_attachments'
          ],
          sub_fields: {
            to: ['id', 'full_name'],
            from: ['id', 'full_name'],
            nutri_mail_attachments: ['id', 'get_item', 'item_file_name']
          },
          per_page: 20,
          page: page,
          order: currentTab === 'inbox' ? 'nutri_mails.read ASC, created_at DESC' : 'created_at DESC',
          filter: {
            search: search,
            advanced: currentTab === 'inbox' ? {
              to_id: login?.id,
              from_id: activeClient?.id,
              sent: 1,
              deleted: '0'
            } : (currentTab === 'sent' ? {
              from_id: login?.id,
              to_id: activeClient?.id,
              sent: 1,
              deleted: '0'
            } : (currentTab === 'drafts' ? {
              from_id: login?.id,
              to_id: activeClient?.id,
              deleted: '0',
              sent: '0'
            } : (currentTab === 'deleted' ? {
              from_id: login?.id,
              to_id: activeClient?.id,
              deleted: 1
            } : ({}))))
          }
        },
        login
      ),
    select: (d) => {
      mailCount = d.count;
      return d.result;
    },
    enabled: !!login,
  });








  const doMenuItem = (key:string, label:string, Icon:ElementType) => {
    return <MenuItem onClick={() => {
      setCurrentTab(key);
    }} sx={{
      borderRadius: 5,
      transition: "background-color 0.5s ease",
      backgroundColor: (currentTab === key ? theme.palette.primary.main : 'transparent'),
      color: (currentTab === key ? 'white' : 'rgba(0, 0, 0, 0.87)'),
      "& span, & svg": {
        transition: "color 0.25s ease"
      },
      "&:hover, &.is-active": {
        backgroundColor: theme.palette.primary.main,
        "& span, & svg": {
          color: `${theme.palette.primary.contrastText} !important`
        }
      },
    }}>
      <ListItemIcon>
        <Icon fontSize="small" sx={{
          color: (currentTab === key ? theme.palette.primary.contrastText : 'rgba(0, 0, 0, 0.87)')
        }} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </MenuItem>;
  }

  useEffect(() => {
    mailsRefetch();
  }, [page, currentTab]);

  useEffect(() => {
    let to = setTimeout(() => {
      mailsRefetch();
    }, 2000);
  
    return () => {
      clearTimeout(to);
    }
  }, [search]);
  
  return (<Grid container spacing={1}>
    <Grid item xs={12} md={2}>
      <Paper sx={{
        padding: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      }}>
        {doMenuItem('inbox', t("shared.nutri_mail.inbox", "Inbox"), InboxIcon)}
        {doMenuItem('sent', t("shared.nutri_mail.sent", "Verzonden"), SendIcon)}
        {doMenuItem('drafts', t("shared.nutri_mail.drafts", "Drafts"), InsertDriveFileIcon)}
        {doMenuItem('deleted', t("shared.nutri_mail.deleted", "Verwijderd"), DeleteIcon)}
      </Paper>
    </Grid>
    <Grid container spacing={1} item xs={12} md={10} sx={{alignContent: 'baseline', paddingBottom: 2}}>
      <Grid item xs={12}>
        <TextInput
          id="search"
          placeholder={t("shared.nutri_mail.search", "Zoeken")}
          value={search}
          muiIcon={<SearchIcon />}
          backend
          noCaption
          onChange={(e) => {
            setSearch(e);
          }}
          sx={{
            marginTop: 0,
            marginBottom: 0
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{
          padding: 1
        }}>
          {mailsLoading && <Box sx={{width: '100%', textAlign: 'center'}}>
            <CircularProgress />
          </Box>}
          {mailsSuccess && mails.length < 1 && <Box sx={{width: '100%', textAlign: 'center'}}>
            <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("shared.nutri_mail.no_mails")}</Typography>
          </Box>}
          
          {mailsSuccess && mails.length > 0 && <Box sx={{width: '100%', textAlign: 'left'}}>
            {mails.map((mail, i) => <InboxNutriMail
              mail={mail}
              border={(mails.length - 1) !== i}
              showTo={currentTab === 'sent' || currentTab === 'drafts'}
              onClick={() => {
                setActiveMail(mail);
              }}
            />)}
          </Box>}
        </Paper>
      </Grid>

      {mailCount > 20 && <Grid item xs={12}>
        <Stack spacing={2} alignItems="center">
          <Pagination
            count={Math.ceil(mailCount/20)}
            page={page + 1}
            onChange={(e, v) => {setPage(v - 1);}}
            boundaryCount={1}
            siblingCount={2}
            size="small"
          />
        </Stack>
      </Grid>}
    </Grid>

    {!!activeMail && <DialogNutriMail
      mail={activeMail}
      open={!!activeMail}
      setOpen={(b:boolean) => {
        if (!b) setActiveMail(undefined);
      }}
      refetch={mailsRefetch}
    />}

    {!!activeClient?.id && <Box sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.warning.main,
      width: '100%',
      padding: 0.5,
      zIndex: 1200
    }}>
      <Typography variant="caption">{t("coach.submenu.nutri_mails.check_for_client_warning", "Je bent nutrimails aan het checken voor")} {activeClient.name} <Button
        sx={{fontSize: '0.7rem', marginLeft: 1}}
        size="small"
        // variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          setActiveClient(undefined);
        }}
      >{t("coach.submenu.agenda.plan_for_client_cancel", "Stoppen")}</Button></Typography>
    </Box>}

    <Fab
      onClick={() => {
        navigate("/inbox/new", {state: {activeClient: activeClient}});
      }}
    />
  </Grid>);
}
