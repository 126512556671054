import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { loginAtom } from '../../../lib/auth';
import { fetch_one, fetch_all } from "../../../lib/v31lib";
import { currentPracticeAtom } from '../../../lib/practice';

import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';

import {
  Box,
  Button as Btn,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
} from '@mui/material';

import {
  ColourPicker,
  MultiSelect,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';
import { Premium } from '../../../components/v2/dialogs/Premium';

import { AppointmentType } from '../../../models/AppointmentType';
import { Location } from '../../../models/Location';
import { Questionnaire } from '../../../models/Questionnaire';
import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {
  onSave: () => void;
  open: boolean;
  setOpen: (b:boolean) => void;
  appointmentType: AppointmentType;
  setAppointmentType: (c:AppointmentType) => void;
  isLoading?: boolean;
};

export const CoachAppointmentTypeEdit: FunctionComponent<Props> = ({open, setOpen, appointmentType, setAppointmentType, onSave, isLoading}) => {
  const theme = useTheme();
  // const navigate = useNavigate();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const {t, i18n} = useTranslation(['translations']);

  const [premiumQuestionnaireOpen, setPremiumQuestionnaireOpen] = useState<boolean>(false);

  let is_questionnaire = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'questionnaire'});


  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: questionnaires,
    // isLoading: coachesLoading,
    // isError: coachesError,
    // isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["questionnaires", currentPractice?.id],
    queryFn: () =>
      fetch_all<Questionnaire>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire',
          fields: ['id', 'name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id)
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id && !!is_questionnaire
  });
  // const {
  //   data: locations,
  //   isLoading: locationsLoading,
  //   isError: locationsError,
  //   isSuccess: locationsSuccess,
  //   // refetch: locationsRefetch,
  // } = useQuery({
  //   queryKey: ["locations", currentPractice?.id],
  //   queryFn: () =>
  //     fetch_all<Location>(
  //       `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
  //       {
  //         object: 'location',
  //         fields: ['id', 'name', 'colour'],
  //         filter: {
  //           search: '',
  //           advanced: {
  //             practice_id: (login?.practice_id || currentPractice?.id),
  //             enabled: '1'
  //           }
  //         }
  //       },
  //       login
  //     ),
  //   enabled: !!currentPractice?.id
  // });





  // useEffect(() => {
  //   console.log(activeSlot);
  // }, [activeSlot]);

  return (<>
    <Base
      name="appointment_type_edit"
      title={`${t("general.titles.edit", "Wijzig")} ${t("coach.models.appointment_types.singular", "Afspraaktype").toLowerCase()}`}
      fullWidth
      fullScreen
      maxWidth="lg"
      dc_sx={{
        backgroundColor: '#f8f8f8'
      }}
      content={<>

        <Grid container sx={{
          marginTop: 1
        }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{t("coach.appointment_types.settings.general_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="name"
              starred
              caption={t("coach.models.appointment_types.name", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.name", "Vul hier de naam in")}
              value={appointmentType.name || ''}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              id="sort_order"
              starred
              caption={t("coach.models.appointment_types.sort_order", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.sort_order", "Vul hier de naam in")}
              value={appointmentType.sort_order?.toString() || '100'}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  sort_order: !!e ? parseInt(e, 10) : undefined
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ColourPicker
              label={t("coach.models.appointment_types.colour", "Kleur")}
              value={appointmentType?.extra_data?.colour || 'rgba(0, 0, 0, 0.4)'}
              onChange={(v) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    colour: v
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              id="duration"
              starred
              caption={t("coach.models.appointment_types.length", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.length", "Vul hier de duurtijd in")}
              value={appointmentType.duration || ''}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  duration: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              id="public_duration"
              starred
              caption={t("coach.models.appointment_types.public_length")}
              placeholder={t("coach.models.appointment_types.placeholders.public_length", "Vul hier de publieke duurtijd in")}
              value={appointmentType.public_duration || ''}
              backend
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  public_duration: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Switcher
              id="note_required"
              caption={t("coach.models.appointment_types.note_required", "Opmerking van client verplicht")}
              value={!!appointmentType?.extra_data?.note_required}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    note_required: !!c
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Switcher
              id="is_meeting"
              caption={t("coach.models.appointment_types.is_meeting")}
              value={!!appointmentType?.is_meeting}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  is_meeting: !!c
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.appointment_types.settings.payments_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="cost"
              caption={t("coach.models.appointment_types.cost", "Naam")}
              placeholder={t("coach.models.appointment_types.placeholders.cost", "Vul hier de kost in")}
              value={appointmentType.extra_data?.cost?.toString() || ''}
              backend
              type="amount"
              onChange={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType.extra_data || {}),
                    cost: !!e ? e.replace(/[^0-9\,\.]/g, '').replace(",", ".") : undefined
                    // cost: !!e ? parseFloat(e) : undefined
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Switcher
              id="mollie_enabled"
              caption={t("coach.models.appointment_types.mollie_enabled", "Betaling mogelijk")}
              value={!!appointmentType?.extra_data?.mollie_settings?.enabled}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  extra_data: {
                    ...(appointmentType?.extra_data || {}),
                    mollie_settings: {
                      ...(appointmentType?.extra_data?.mollie_settings || {}),
                      enabled: !!currentPractice?.settings?.mollie_api_key && !!c
                    }
                  }
                });
              }}
            />
            {!currentPractice?.settings?.mollie_api_key && <Typography variant="caption"sx={{color: theme.palette.secondary.main}}>{t("coach.models.appointment_types.mollie_enabled_error")}</Typography>}
          </Grid>

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.appointment_types.settings.admin_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="enabled"
              caption={t("coach.models.appointment_types.enabled", "Zet dit afspraaktype aan of uit")}
              value={!!appointmentType?.enabled}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  enabled: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Switcher
              id="is_hidden"
              caption={t("coach.models.appointment_types.is_hidden")}
              value={!!appointmentType?.is_hidden}
              onChange={(c) => {
                setAppointmentType({
                  ...appointmentType,
                  is_hidden: !!c
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{t("coach.models.appointment_types.coaches", "Wie heeft toegang tot dit type")}</Typography>
          </Grid>
          {(coaches || []).map(coach => <Grid item xs={12} md={3}>
            <Switcher
              id={`coach${coach.id}`}
              caption={coach.full_name}
              greying
              value={(appointmentType?.coaches || []).indexOf(coach.id || 0) < 0}
              onChange={(c) => {
                if ((appointmentType?.coaches || []).indexOf(coach.id || 0) < 0) {
                  setAppointmentType({
                    ...appointmentType,
                    coaches: [...(appointmentType?.coaches || []), (coach.id || 0)]
                  });
                } else {
                  setAppointmentType({
                    ...appointmentType,
                    coaches: (appointmentType?.coaches || []).filter(fff => fff !== (coach.id || 0))
                  });
                }
              }}
            />
          </Grid>)}

          <Grid item xs={12} sx={{marginTop: 3}}>
            <Typography variant="h5">{t("coach.appointment_types.settings.questionnaires_title")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{position: 'relative'}}>
            {(questionnaires || []) && <MultiSelect
              id="questionnaires"
              caption={t("coach.models.appointment_types.questionnaires")}
              backend
              value={(appointmentType?.questionnaires || [])}
              chips
              options={(questionnaires|| []).map(mm => {return {id: mm.id || 0, name: mm.name || ''};})}
              setValue={(e) => {
                setAppointmentType({
                  ...appointmentType,
                  questionnaires: e.map(mm => parseInt(mm.toString(), 10))
                });
              }}
            >
              <MenuItem value=""></MenuItem>
              {(questionnaires || []).map(questionnaire => <MenuItem value={questionnaire.id}>{questionnaire.name}</MenuItem>)}
            </MultiSelect>}
            {!is_questionnaire && <Box sx={{
              position: 'absolute',
              top: 8,
              left: 8,
              width: '100%',
              zIndex: 1201,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              height: '100%',
              textAlign: 'center',
              cursor: 'pointer'
            }} onClick={() => {
              setPremiumQuestionnaireOpen(true);
            }}>
              <StarBorderPurple500 sx={{marginTop: 2, color: theme.palette.primary.main}} />
            </Box>}
          </Grid>
        </Grid>
      </>}
      actions={<DialogActions>
        <Btn
          onClick={(e) => {
            setOpen(false);
          }}
          color="primary"
        >{t("coach.general.actions.close")}</Btn>
        <Btn
          onClick={onSave}
          disabled={!appointmentType.name}
          color="primary"
        >{t("client.general.buttons.submit")}</Btn>
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />

    <Premium
      open={premiumQuestionnaireOpen}
      setOpen={(b) => {
        setPremiumQuestionnaireOpen(b);
      }}
      mode="questionnaire"
    />
  </>);
}
