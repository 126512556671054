import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  FileUpload,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Bug } from '../../../models/Bug';
// import { User } from '../../../models/User';

// import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const AdminBugEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Bug>({
    title: ''
  });

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["bug", objectId],
    queryFn: () =>
      fetch_one<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'bug',
          id: objectId,
          fields: ['id', 'title', 'status', 'created_at', 'content', 'bug_type', 'impact', 'get_item', 'item_file_name', 'practice_id', 'user_id', 'owner_id', 'ping_owner', 'ping_user', 'extra_data', 'practice|id/name', 'owner|id/full_name']
        },
        login
      ),
    // select: (d) => {
    //   return {
    //     ...d,
    //     published_at: DateTime.fromISO(d.published_at).toFormat("dd/LL/y"),
    //     // end_time: DateTime.fromISO(d.end_time).toFormat("dd/LL/y t"),
    //     // background_colour: d.background_colour || 'rgb(211, 211, 211)'
    //   };
    // },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Bug>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["admin", "bugs", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) navigate("/bugs");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/bug/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  
  let termSingle = t("admin.models.news_items.singular", "Nieuwsbericht");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = (mainObject || {}).title || termSingle.toLowerCase();
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).title;
  }

  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: t("admin.menu.imports", "Bugs"),
        path: "/bugs"
      }
    ]}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">{t("general.bugs.section.basic")}</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          fullWidth
          caption={t("general.bugs.fields.bug_type", "Type bugrapport")}
          starred
          backend
          displayEmpty
          id="bug_type"
          value={mainObject.bug_type?.toString() || '0'}
          setValue={(v) => {
            setMainObject({
              ...mainObject,
              bug_type: !!v ? parseInt(v.toString(), 10) : undefined
            });
          }}
        >
          <MenuItem value="0">{t("general.bugs.fields.bug_types.bug", "Bug")}</MenuItem>
          <MenuItem value="1">{t("general.bugs.fields.bug_types.feature", "Feature")}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} md={6}>
        {mainObject.bug_type === 0 && <Select
          fullWidth
          caption={t("general.bugs.fields.impact", "Impact")}
          backend
          displayEmpty
          id="impact"
          value={mainObject.impact?.toString() || '0'}
          setValue={(v) => {
            setMainObject({
              ...mainObject,
              impact: !!v ? parseInt(v.toString(), 10) : undefined
            });
          }}
        >
          <MenuItem value="0">{t("general.bugs.fields.impacts.none", "Geen impact")}</MenuItem>
          <MenuItem value="1">{t("general.bugs.fields.impacts.small", "Weinig impact (workaround beschikbaar)")}</MenuItem>
          <MenuItem value="2">{t("general.bugs.fields.impacts.much", "Veel impact (geen workaround, maar ik kan verder)")}</MenuItem>
          <MenuItem value="3">{t("general.bugs.fields.impacts.breaking", "Brekend (ik kan niet verder)")}</MenuItem>
        </Select>}
      </Grid>
      <Grid item xs={12}>
        <TextInput
          id="title"
          caption={t("general.bugs.fields.title", "Onderwerp")}
          starred
          backend
          value={mainObject.title || ''}
          onChange={(e) => {
            setMainObject({
              ...mainObject,
              title: e
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{fontSize: '0.8rem'}}>{t("general.bugs.fields.content", "Content")}:</Typography>
        <Editor
          advanced
          label=""
          value={mainObject.content || ''}
          onChange={(e) => {
            setMainObject({
              ...mainObject,
              content: e
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FileUpload
          caption={t("general.bugs.fields.item", "Bijlage")}
          fileInputName='item'
          selected={mainObject.item}
          onChange={(f) => {
            setMainObject({
              ...mainObject,
              item: f as File
            });
          }}
        />
        {!!mainObject.item_file_name && <Button
          label={mainObject.item_file_name}
          id='download'
          text
          startIcon={<CloudDownloadIcon />}
          onClick={(v) => {
            window.open(mainObject?.get_item, "BLANK");
          }}
        />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{t("general.bugs.section.admin")}</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <Select
          fullWidth
          caption={t("general.bugs.fields.status", "Status")}
          backend
          displayEmpty
          id="status"
          value={mainObject.status?.toString() || '0'}
          setValue={(v) => {
            setMainObject({
              ...mainObject,
              status: !!v ? parseInt(v.toString(), 10) : undefined
            });
          }}
        >
          <MenuItem value="0">{t("general.bugs.fields.statusses.open", "Open")}</MenuItem>
          <MenuItem value="1">{t("general.bugs.fields.statusses.pending", "Pending")}</MenuItem>
          <MenuItem value="2">{t("general.bugs.fields.statusses.closed", "Closed")}</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">{t("general.bugs.section.actions")}</Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Under construction</Typography>
      </Grid>


      



      <Grid item xs={12} sx={{
        alignContent: 'baseline',
        textAlign: 'center',
        position: {
              xs: 'relative',
              md: 'sticky'
            },
        bottom: '-24px',
        backgroundColor: 'white',
        zIndex: 1201
      }}>
        <Divider sx={{marginTop: 1, marginBottom: 0}} />

        {!!mutationSave.isPending && <CircularProgress />}
        {!mutationSave.isPending && <Button
          label={t("shared.buttons.save")}
          id='save'
          disabled={!mainObject.title}
          contained
          onClick={(v) => {
            let formData = new FormData();
            formData.append('object', 'bug');
            formData.append('handler_id', (login?.id || "").toString());
            formData.append('fields[]', 'id');
            if (!!mainObject?.id) formData.append('ob[id]', mainObject?.id.toString());
            if (!!mainObject?.id) formData.append('id', mainObject?.id.toString());
            // mainObject
            formData.append('ob[title]', mainObject?.title || '');
            formData.append('ob[content]', mainObject?.content || '');
            formData.append('ob[bug_type]', mainObject?.bug_type?.toString() || '');
            formData.append('ob[impact]', mainObject?.impact?.toString() || '');
            formData.append('ob[practice_id]', mainObject?.practice_id?.toString() || '');
            formData.append('ob[owner_id]', mainObject?.owner_id?.toString() || '');
            formData.append('ob[user_id]', mainObject?.user_id?.toString() || '');
            formData.append('ob[status]', mainObject?.status?.toString() || '');
            if (!!mainObject?.item) formData.append(`ob[item]`, mainObject?.item);

            mutationSave.mutate({
              formData: formData
            });
          }}
          sx={{marginBottom: 1, marginTop: 1}}
        />}
        {!mutationSave.isPending && <Button
          label={t("shared.buttons.cancel")}
          id='cancel'
          text
          // contained
          onClick={(v) => {
            navigate("/bugs");
          }}
          sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
        />}
      </Grid>
    </Grid>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
