import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EuroIcon from '@mui/icons-material/Euro';
import TextsmsIcon from '@mui/icons-material/Textsms';

import {
  Avatar,
  Box,
  Button as Btn,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  ImageListItem,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Tooltip,
} from '@mui/material';

import {
  Dt,
  Fab,
  ImageList,
  Page,
  Typography
} from '../../../components/v2/styled';
import { CardAppointment } from '../../../components/v2/cards/Appointment';
import { CoachCancelAppointment } from '../../../components/v2/dialogs/CoachCancelAppointment';
import { CoachEditAppointment } from '../../../components/v2/dialogs/CoachEditAppointment';
// import { Premium } from '../../../components/v2/dialogs/Premium';
// import { SnackCopied } from '../../../components/v2/snacks/Copied';

import { Appointment } from '../../../models/Appointment';
import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachFmUserAppointments: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  // const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [clientId, setClientId] = useState<string>();
  const [practiceKey, setPracticeKey] = useState<string>();
  // const [mainObject, setMainObject] = useState<User>({
  //   auth_token: '',
  //   email: '',
  //   coach_skills: []
  // });
  const [editAppointment, setEditAppointment] = useState<Appointment>();
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [cancelAppointment, setCancelAppointment] = useState<Appointment>();
  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [cancelNote, setCancelNote] = useState<string>('');
  const [cancelNoShow, setCancelNoShow] = useState<boolean>(false);
  let count = 0;
  let soonCount = 0;
  let futureCount = 0;
  const [page, setPage] = useState<number>(0);

  let is_nutri_appointment = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_appointment'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'short_email', clientId, practiceKey],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          client_id: clientId || '-',
          practice_key: practiceKey || '-',
          fields: [
            'id', 'first_name', 'last_name', 'email'
          ],
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!login && !!clientId && !!practiceKey,
  });

  const {
    data: appointmentsSoon,
    isLoading: appointmentsSoonLoading,
    isError: appointmentsSoonError,
    isSuccess: appointmentsSoonSuccess,
    refetch: appointmentsSoonRefetch,
  } = useQuery({
    queryKey: ["appointments_soon", login?.id],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow',
            'has_consent', 'can_meeting', 'comment', 'is_notify_sms', 'is_notify_mail',
            'coach', 'location', 'practice', 'payment', 'appointment_type',
            'comment_cancel', 'order|id/paid', 'comment_coach', 'handlers'
          ],
          sub_fields: {
            'coach': ['id', 'get_medium', 'coach_skills', 'full_name', 'enabled'],
            'location': ['id', 'get_medium', 'name', 'is_online', 'enabled', 'settings', 'street', 'number', 'zip', 'city'],
            'practice': ['id', 'name', 'settings'],
            'payment': ['id', 'paid'],
            'appointment_type': ['id', 'colour', 'cost', 'duration_nice', 'name']
          },
          page: 0,
          per_page: 20,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              user_id: object?.id,
              practice_id: currentPractice?.id,
              soon: 1
            }
          }
        },
        login
      ),
      select: (d) => {
        soonCount = d.count;
        return d.result;
      },
    enabled: !!object,
  });

  const {
    data: appointmentsFuture,
    isLoading: appointmentsFutureLoading,
    isError: appointmentsFutureError,
    isSuccess: appointmentsFutureSuccess,
    refetch: appointmentsFutureRefetch,
  } = useQuery({
    queryKey: ["appointments_future", login?.id],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow',
            'has_consent', 'can_meeting', 'comment', 'is_notify_sms', 'is_notify_mail',
            'coach', 'location', 'practice', 'payment', 'appointment_type',
            'comment_cancel', 'order|id/paid', 'comment_coach', 'handlers'
          ],
          sub_fields: {
            'coach': ['id', 'get_medium', 'coach_skills', 'full_name', 'enabled'],
            'location': ['id', 'get_medium', 'name', 'is_online', 'enabled', 'settings', 'street', 'number', 'zip', 'city'],
            'practice': ['id', 'name', 'settings'],
            'payment': ['id', 'paid'],
            'appointment_type': ['id', 'colour', 'cost', 'duration_nice', 'name']
          },
          page: 0,
          per_page: 20,
          order: "start_time ASC",
          filter: {
            search: '',
            advanced: {
              user_id: object?.id,
              practice_id: currentPractice?.id,
              future: 1
            }
          }
        },
        login
      ),
      select: (d) => {
        futureCount = d.count;
        return d.result;
      },
    enabled: !!object,
  });

  const {
    data: appointmentsPast,
    isLoading: appointmentsPastLoading,
    isError: appointmentsPastError,
    isSuccess: appointmentsPastSuccess,
    refetch: appointmentsPastRefetch,
  } = useQuery({
    queryKey: ["appointments_past", login?.id, page],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: [
            'id', 'user_id', 'start_time', 'end_time', 'is_cancelled', 'is_cancelled_by_coach', 'is_cancelled_late', 'is_noshow', 'location|id/get_item/name/is_online/street_line/city_line', 'coach|id/get_item/coach_skills/full_name', 'practice|id/name', 'comment_cancel', 'order|id/paid',
            'appointment_type|id/colour/cost/duration_nice/name', 'comment', 'comment_coach', 'handlers'
          ],
          page: page,
          per_page: 10,
          order: "start_time DESC",
          filter: {
            search: '',
            advanced: {
              user_id: object?.id,
              practice_id: currentPractice?.id,
              past: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!object,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      appointmentsSoonRefetch();
      appointmentsFutureRefetch();
      appointmentsPastRefetch();
      setCancelOpen(false);
      setCancelAppointment(undefined);
    },
  });





  const [open, setOpen] = useState<number>();

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  // useEffect(() => {
  //   if (!!objectSuccess) setMainObject(object);
  // }, [object]);
  useEffect(() => {
    setClientId(searchParams.get("client_id") || undefined);
  }, [searchParams.get("client_id")]);
  useEffect(() => {
    setPracticeKey(searchParams.get("practice_key") || undefined);
  }, [searchParams.get("practice_key")]);

  return <Page
    title={t("coach.menu.appointments")}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      },
      {
        title: !!object?.first_name ? [object?.first_name, object?.last_name].join(" ") : 'Cliënt',
        path: `/user/${object?.id}/detail`
      }
    ]}
    premiumGatekeeping={!!is_nutri_appointment}
    premiumMode='nutri_appointment'
  >
    {!!objectLoading && <Grid container spacing={1}>
      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Grid>
    </Grid>}
    {!!objectSuccess && (appointmentsSoon || []).length < 1 && (appointmentsFuture || []).length < 1 && (appointmentsPast || []).length < 1 && <Grid item xs={12}>
      <Typography sx={{fontStyle: 'italic'}}>{t("shared.terms.no_results", "Er zijn geen resultaten beschikbaar")}</Typography>
    </Grid>}
    {!!objectSuccess && <Grid container spacing={2}>
      {!!appointmentsSoonLoading && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.soon")}</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <CircularProgress />
          </Paper>
        </Box>
      </Grid>}
      {!!appointmentsSoonSuccess && appointmentsSoon?.length > 0 && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.soon")} ({soonCount})</Typography>
          <ImageList cardWidth={400}>
            {(appointmentsSoon || []).map(appointment => <ImageListItem key={appointment.id} sx={{
              height: "auto !important"
            }}>
              <CardAppointment
                appointment={appointment}
                isCoach
                extensive
                actions
                onEdit={(app) => {
                  // if (DateTime.now() >= DateTime.fromISO(app.start_time).minus({hours: app.practice?.settings?.cancel_late_time || 24})) {
                  //   setTooLateOpen(true);
                  // } else {
                    setEditOpen(true);
                    setEditAppointment(app);
                  // }
                }}
                onDelete={(app) => {
                  setCancelAppointment(app);
                  setCancelOpen(true);
                }}
              />
            </ImageListItem>)}
          </ImageList>
        </Box>
      </Grid>}


      {!!appointmentsFutureLoading && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.future")}</Typography>
          <Paper sx={{
            padding: 2
          }}>
            <CircularProgress />
          </Paper>
        </Box>
      </Grid>}
      {!!appointmentsFutureSuccess && appointmentsFuture?.length > 0 && <Grid item xs={12}>
        <Box>
          <Typography variant={"subtitle1"}>{t("client.appointments.future")} ({futureCount})</Typography>
          <ImageList cardWidth={400}>
            {(appointmentsFuture || []).map(appointment => <ImageListItem key={appointment.id} sx={{
              height: "auto !important"
            }}>
              <CardAppointment
                appointment={appointment}
                isCoach
                extensive
                actions
                onEdit={(app) => {
                  // if (DateTime.now() >= DateTime.fromISO(app.start_time).minus({hours: app.practice?.settings?.cancel_late_time || 24})) {
                  //   setTooLateOpen(true);
                  // } else {
                    setEditOpen(true);
                    setEditAppointment(app);
                  // }
                }}
                onDelete={(app) => {
                  setCancelAppointment(app);
                  setCancelOpen(true);
                }}
              />
            </ImageListItem>)}
          </ImageList>
        </Box>
      </Grid>}
    </Grid>}

    {!!appointmentsPastLoading && <Grid item xs={12}>
      <Box>
        <Typography variant={"subtitle1"}>{t("client.appointments.past")}</Typography>
        <Paper sx={{
          padding: 2
        }}>
          <CircularProgress />
        </Paper>
      </Box>
    </Grid>}
    {!!appointmentsPastSuccess && appointmentsPast?.length > 0 && <Grid item xs={12}>
      <Box>
        <Typography variant={"subtitle1"}>{t("client.appointments.past")} ({count})</Typography>
        <Paper sx={{
          padding: 2
        }}>
          <List>
            {appointmentsPast.map(appointment => (<>
              <ListItem divider={appointmentsPast.indexOf(appointment) < appointmentsPast.length - 1 && open !== appointment.id} sx={{
                ...(appointment.is_cancelled ? (appointment.is_noshow ? {
                  backgroundColor: 'rgba(255, 0, 0, 0.65);'
                } : {
                  backgroundColor: 'rgba(255, 0, 0, 0.2);'
                }) : {})
              }}>
                <ListItemAvatar>
                  <Avatar src={(appointment.location_get_item ? appointment.location_get_item : (appointment.coach_get_item ? appointment.coach_get_item : ""))}></Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Dt d={appointment.start_time} f="cccc DDD - t" />}
                  secondary={appointment.practice_name}
                />
                <ListItemSecondaryAction>

                  {!!appointment.comment_cancel && open !== appointment.id && <Tooltip title={appointment.comment_cancel}>
                    <IconButton sx={{marginLeft: 'auto'}}>
                      <TextsmsIcon />
                    </IconButton>
                  </Tooltip>}

                  <IconButton onClick={(e) => {
                    e.preventDefault();
                    setOpen(open === appointment.id ? undefined : appointment.id);
                  }} sx={{marginLeft: 'auto'}}>
                    {open === appointment.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>

                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={open === appointment.id} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {appointment.order_id && appointment.order_paid && <ListItem divider={false} sx={{
                    paddingLeft: 8,
                  }}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: theme.palette.primary.main}}><EuroIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t("client.payments.appointment.paid", "Deze afspraak werd reeds betaald")}
                    />
                  </ListItem>}

                  {appointment.order_id && !appointment.order_paid && <ListItem divider={false} sx={{
                    paddingLeft: 8,
                  }}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: 'red'}}><EuroIcon /></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t("client.payments.appointment.unpaid", "Deze afspraak werd nog niet betaald")}
                    />
                    {!!appointment.order_token && <ListItemSecondaryAction>
                      <IconButton onClick={(e) => {window.open(`https://nutripay.eu/${appointment.order_token}`, '_blank');}} sx={{marginLeft: 'auto'}}>
                        <EuroIcon />
                      </IconButton>
                    </ListItemSecondaryAction>}
                  </ListItem>}

                  {appointment.is_cancelled && <ListItem divider={false} sx={{
                    paddingLeft: 8,
                    color: theme.palette.secondary.main
                  }}>
                    <ListItemAvatar />
                    <ListItemText
                      primary={appointment.is_cancelled_by_coach ? t("client.appointments.cancelled_coach") : t("client.appointments.cancelled_client")}
                      secondary={[appointment.is_noshow ? t("client.appointments.cancelled_noshow") : null, appointment.is_cancelled_late ? t("client.appointments.cancelled_late") : null].filter(f => !!f).join(" | ")}
                    />
                  </ListItem>}

                  {appointment.is_cancelled && appointment.comment_cancel && <ListItem divider={false} sx={{
                    paddingLeft: 8
                  }}>
                    <ListItemAvatar />
                    <ListItemText
                      sx={{fontStyle: 'italic'}}
                      primary={appointment.comment_cancel}
                    />
                  </ListItem>}

                  <ListItem divider={false} sx={{
                    paddingLeft: 8
                  }}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: appointment.appointment_type_colour}}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={appointment.appointment_type_name}
                      secondary={`${t("client.appointment.type_duration", "Duurtijd")}: ${appointment.appointment_type_duration_nice} | ${t("client.appointment.type_cost", "Kost")}: ${appointment.appointment_type_cost} €`}
                    />
                  </ListItem>

                  <ListItem divider={false} sx={{
                    paddingLeft: 8
                  }}>
                    <ListItemAvatar>
                      <Avatar src={appointment.location_get_item ? appointment.location_get_item : ''}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={appointment.location_name}
                      secondary={(appointment.location_is_online ? t("client.appointment.online_location", "Online consultatie") : `${appointment.location_street_line}, ${appointment.location_city_line}`)}
                    />
                  </ListItem>

                  <ListItem divider={true} sx={{
                    paddingLeft: 8
                  }}>
                    <ListItemAvatar>
                      <Avatar src={appointment.coach_get_item ? appointment.coach_get_item : ''}></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={appointment.coach_full_name}
                      secondary={(appointment.coach_coach_skills || []).map(s => s.name).join(', ')}
                    />
                  </ListItem>

                </List>
              </Collapse>
            </>))}
          </List>
          <Stack spacing={2} alignItems="center">
            <Pagination count={Math.ceil(count/10)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
          </Stack>
        </Paper>
      </Box>
    </Grid>}

    {!!cancelAppointment && <CoachCancelAppointment
      open={cancelOpen}
      setOpen={setCancelOpen}
      note={cancelNote}
      setNote={setCancelNote}
      noShow={cancelNoShow}
      setNoShow={setCancelNoShow}
      onDelete={() => {
        mutationCustomAction.mutate({
          formData: {
            object: 'appointment',
            class_action: 'custom_api_cancel_appointment',
            ob: {
              appointment_id: cancelAppointment.id,
              comment_cancel: cancelNote,
              no_show: cancelNoShow
            },
            practice_id: currentPractice?.id
          }
        });
      }}
    />}

    {!!editOpen && !!editAppointment && <CoachEditAppointment
      open={editOpen}
      setOpen={setEditOpen}
      appointment={editAppointment}
      refreshAppointments={() => {
        appointmentsSoonRefetch();
        appointmentsFutureRefetch();
        appointmentsPastRefetch();
      }}
    />}

    <Fab
      onClick={() => {
        navigate("/agenda", {state: {user_id: object?.id, first_name: object?.first_name, last_name: object?.last_name, email: object?.email}});
      }}
    />
    
  </Page>;
}
