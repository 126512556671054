import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
// import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import BusinessIcon from '@mui/icons-material/Business';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import EuroIcon from '@mui/icons-material/Euro';
import MailLockIcon from '@mui/icons-material/MailLock';
import PlaceIcon from '@mui/icons-material/Place';
import QuizIcon from '@mui/icons-material/Quiz';

import {
  Box,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';

import {
  Page,
  // Typography
} from '../../components/v2/styled';
import { CoachGeneralSettingsAppointments } from './general_settings/Appointments';
import { CoachGeneralSettingsEmailTemplates } from './general_settings/EmailTemplates';
import { CoachGeneralSettingsLocations } from './general_settings/Locations';
import { CoachGeneralSettingsPayments } from './general_settings/Payments';
import { CoachGeneralSettingsPractice } from './general_settings/Practice';
import { CoachGeneralSettingsQuestionnaires } from './general_settings/Questionnaires';
import { CoachGeneralSettingsWorkshops } from './general_settings/Workshops';

import HelpersPractice from '../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachGeneralSettings: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();

  const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);

  let is_nutri_appointment = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_appointment'});
  let is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});
  let is_payments = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'payments'});
  let is_questionnaire = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'questionnaire'});

  return <Page>
    <Box>
      <Box sx={{
        marginBottom: 3,
        '& .Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)'
        }
      }}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => {setCurrentTab(v);}}
          indicatorColor="primary"
          textColor="primary"
          aria-label="Filters"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            position: 'relative',
            zIndex: 1202
          }}
        >
          <Tab icon={<BusinessIcon />} label={t("coach.settings.practice.title")} />
          <Tab icon={<CalendarViewWeekIcon />} label={t("coach.settings.appointments.title")} />
          <Tab icon={<CoPresentIcon />} label={t("coach.settings.workshops.title")} />
          <Tab icon={<PlaceIcon />} label={t("coach.settings.locations.title")} />
          <Tab icon={<MailLockIcon />} label={t("coach.settings.email_templates.title")} />
          <Tab icon={<EuroIcon />} label={t("coach.settings.payments.title")} />
          <Tab icon={<QuizIcon />} label={t("coach.settings.questionnaires.title")} />
        </Tabs>
        <Divider sx={{borderColor: theme.palette.primary.main}} />
      </Box>


      {currentTab === 0 && login?.is_super && <CoachGeneralSettingsPractice />}
      {currentTab === 1 && login?.is_super && <CoachGeneralSettingsAppointments premiumLocked={!is_nutri_appointment} />}
      {currentTab === 2 && login?.is_super && <CoachGeneralSettingsWorkshops premiumLocked={!is_nutri_workshop} />}
      {currentTab === 3 && login?.is_super && <CoachGeneralSettingsLocations />}
      {currentTab === 4 && login?.is_super && <CoachGeneralSettingsEmailTemplates />}
      {currentTab === 5 && login?.is_super && <CoachGeneralSettingsPayments />}
      {currentTab === 6 && login?.is_super && <CoachGeneralSettingsQuestionnaires premiumLocked={!is_questionnaire} />}
    </Box>
  </Page>;
}

