import React, { FunctionComponent, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';
import { getCookie } from 'react-use-cookie';

import { fetch_one, check_subdomain } from '../lib/server_helper_new';
import { loginAtom } from '../lib/auth';
import { currentPracticeAtom } from '../lib/practice';

import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';

import '../i18n.ts';
import Background from '../images/nutriportalbackground.png';

import { AdminBugs } from './admin/bugs/Bugs';
import { AdminBugEdit } from './admin/bugs/BugEdit';
import { AdminContentIndex } from './admin/content/IndexPage';
import { AdminContentEdit } from './admin/content/EditPage';
import { AdminDashboard } from './admin/Dashboard';
import { AdminEmailTemplates } from './admin/email_templates/EmailTemplates';
import { AdminEmailTemplateEdit } from './admin/email_templates/EmailTemplateEdit';
import { AdminImports } from './admin/Imports';
import { AdminMergeRequests } from './admin/merge_requests/MergeRequests';
import { AdminNews } from './admin/News';
import { AdminNewsItems } from './admin/news_items/NewsItems';
import { AdminNewsItemEdit } from './admin/news_items/NewsItemEdit';
import { AdminPractices } from './admin/practices/Practices';
import { AdminPracticeEdit } from './admin/practices/PracticeEdit';
import { AdminPracticeTheming } from './admin/practices/Theming';
import { AdminSmsDashboard } from './admin/SmsDashboard';
import { AdminUserPage } from './admin/UserPage';

import { ClientAppointmentNew } from './client/appointment/New';
import { ClientAppointmentList } from './client/appointment/List';
import { ClientAppointmentWorkshops } from './client/appointment/Workshops';
import { ClientDashboard } from './client/Dashboard';
import { ClientDiary } from './client/Diary';
import { ClientDiaryWeek } from './client/DiaryWeek';
import { ClientEditNutriMail } from './client/mail/EditNutriMail';
import { ClientIntake } from './client/Intake';
import { ClientMeasuresWake } from './client/measure/Wake';
import { ClientMeasuresWeight } from './client/measure/Weight';
import { ClientNews } from './client/News';
import { ClientPracticeFindPractice } from './client/practice/FindPractice';
import { ClientPracticeMyPractice } from './client/practice/MyPractice';
import { ClientQuestionnaires } from './client/questionnaires/Questionnaires';
import { ClientQuestionnaireEdit } from './client/questionnaires/QuestionnaireEdit';
import { ClientSettings } from './client/Settings';

import { CoachDashboard } from './coach/Dashboard';
import { CoachAgenda } from './coach/Agenda';
import { CoachEditMail } from './coach/ehealth/EditMail';
import { CoachExports } from './coach/Exports';
import { CoachExportEdit } from './coach/ExportEdit';
import { CoachLogs } from './coach/Logs';
import { CoachEditNutriMail } from './coach/mail/EditNutriMail';
import { CoachGeneralSettings } from './coach/GeneralSettings';
import { CoachNews } from './coach/News';
import { CoachNewsItems } from './coach/news_items/NewsItems';
import { CoachNewsItemEdit } from './coach/news_items/NewsItemEdit';
import { CoachOverrideEdit } from './coach/overrides/OverrideEdit';
import { CoachQuestionnaires } from './coach/questionnaires/Questionnaires';
import { CoachQuestionnaireEdit } from './coach/questionnaires/QuestionnaireEdit';
import { CoachQuestionnaireSubs } from './coach/questionnaires/Subs';
import { CoachSettings } from './coach/Settings';
import { CoachUsers } from './coach/users/Users';
import { CoachFmUserAppointments } from './coach/users/FmUserAppointments';
import { CoachUserAppointments } from './coach/users/UserAppointments';
import { CoachFmUserDetail } from './coach/users/FmUserDetail';
import { CoachUserDetail } from './coach/users/UserDetail';
import { CoachUserDiary } from './coach/users/UserDiary';
import { CoachUserIntake } from './coach/users/UserIntake';
import { CoachUserMeasures } from './coach/users/UserMeasures';
import { CoachUserMerge } from './coach/users/UserMerge';
import { CoachUserMergeRequests } from './coach/users/MergeRequests';
import { CoachUserEdit } from './coach/users/UserEdit';
import { CoachWorkshops } from './coach/nutri_calendar/Workshops';
import { CoachWorkshopEdit } from './coach/nutri_calendar/WorkshopEdit';
import { CoachWorkshopSubscribers } from './coach/nutri_calendar/WorkshopSubscribers';

import { BugTracker } from './shared/BugTracker';
import { EhealthMail } from './shared/EhealthMail';
import { SharedImpersonateLoader } from './shared/ImpersonateLoader';
import { NutriMail } from './shared/NutriMail';
import { SharedLayout } from './shared/Layout';
import { SharedConfirmLoader } from './shared/ConfirmLoader';
import { SharedFind } from './shared/Find';
import { SharedMeeting } from './shared/Meeting';
import { SharedNewsItem } from './shared/NewsItem';
import { SharedOnboarding } from './shared/Onboarding';
import { SharedRedirectLoader } from './shared/RedirectLoader';

import {
  Box,
  CssBaseline
} from '@mui/material';

import { Practice } from '../models/Practice';

type Props = {}

export const Main: FunctionComponent<Props> = () => {
  const login = useAtomValue(loginAtom);
  let subdomain = check_subdomain();

  const defaultTheme = createTheme({
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeightLight: 100,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
  
      h1: {
        fontSize: "1.75rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h2: {
        fontSize: "1.6rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h3: {
        fontSize: "1.35rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h4: {
        fontSize: "1.2rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h5: {
        fontSize: "1.1rem",
        fontFamily: 'Archivo',
      },
      h6: {
        // color: 'rgb(16, 46, 86)'
      },
      body1: {
        // color: 'rgb(16, 46, 86)'
      },
      body2: {
        // color: 'rgb(16, 46, 86)'
      }
    },
    palette: {
      primary: {
        light: '#abcb88',
        main: '#97BE6B',
        dark: '#69854a',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#be6b6b', // red
        light: '#cb8888',
        dark: '#854a4a',
        contrastText: '#FFFFFF'
      },
      tertiary: {
        main: '#DEBC5A'
      },
      warning: {
        main: '#E69E54',
        fade: 'rgba(230, 157, 84, 0.1)'
      },
      lightgray: "rgb(244,248,247)",
      transparant: "transparent",
      mode: 'light',
      background: {
        default: "#f8f8f8",
        // lighter: "#ffffff"
      },
      fancy_bg_img: `url(${Background})`,
    },
    shape: {
      borderRadius: 18
    },
    drawer_width: 240,
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ownerState, theme}) => ({
            fontFamily: 'Archivo',
            fontWeight: 400
          })
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h1' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h2' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h3' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h4' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h5' },
            style: {
              fontFamily: 'Archivo',
            },
          },
        ]
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingTop: 12,
            paddingBottom: 12
          }
        }
      }
    }
  });

  const [theme, setTheme] = useState<Theme>(defaultTheme);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  
  useEffect(() => {
    if (subdomain.length > 0) {
      fetch_one<Practice>(`/nl/v3/pub/define_practice`, {
        object: 'practice',
        fields: ['id', 'custom_theme', 'get_item', 'settings', 'name', 'item_is_square', 'get_medium', 'group_move', 'group_eat', 'modules', 'get_description', 'url_path'],
        sub_fields: {
          modules: ['id', 'name']
        },
        subdomain: subdomain,
        full_url: window.location.host
      }, (ob, complete_data) => {
        setCurrentPractice(ob);
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [subdomain]);
  
  useEffect(() => {
    // console.log(login);
    // console.log(currentPractice);
    // console.log(`p- ${!!currentPractice} nota- ${!is_admin} --- switching theme`);
    if (!!currentPractice && subdomain !== 'admin') {
      if (!!currentPractice && !!currentPractice.custom_theme && !!currentPractice.custom_theme.primary_main && !!currentPractice.custom_theme.contrast_main && !!currentPractice.custom_theme.tertiary_main) {
        let pm = currentPractice.custom_theme.primary_main;
        if (pm === 'undefined') pm = '#97BE6B';
        let pmd = currentPractice.custom_theme.primary_main_dark;
        if (pmd === 'undefined') pmd = '#97BE6B';
        let pml = currentPractice.custom_theme.primary_main_light;
        if (pml === 'undefined') pml = '#97BE6B';
        let cm = currentPractice.custom_theme.contrast_main;
        if (cm === 'undefined') cm = '#FFFFFF';
        let tm = currentPractice.custom_theme.tertiary_main;
        if (tm === 'undefined') tm = '#F9D971';
        let bg = currentPractice.custom_theme.onboarding_bg;
        if (!bg || bg.indexOf('missing') > -1) bg = Background;
        let bg_theme = `url(${bg})`;

        setTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              main: pm,
              dark: pmd || theme.palette.primary.dark,
              light: pml || theme.palette.primary.light,
              contrastText: cm
            },
            tertiary: {
              ...theme.palette.tertiary,
              main: tm
            },
            fancy_bg_img: bg_theme
          }
        });
      }
    }
  }, [currentPractice, login]);

  let is_admin = login && login.type === "User::Admin";
  let is_coach = login && login.type === "User::Coach";
  let is_client = login && login.type === "User::User";

  return (<ThemeProvider theme={theme}>
    <CssBaseline></CssBaseline>
    <Box sx={{
      display: "flex",
      // minHeight: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}></Box>

    {/* <Routes>
      <Route path='/apis/fetch_user' element={<SharedRedirectLoader url={`https://api.nutriportal.eu/filemaker/${(currentPractice?.id || 0)}/do_fetch${window.location.search}`} />} />
      <Route path='/apis/create_user' element={<SharedRedirectLoader url={`https://api.nutriportal.eu/filemaker/${(currentPractice?.id || 0)}/do_create${window.location.search}`} />} />
      <Route path='/apis/create_nutrimail' element={<SharedRedirectLoader url={`https://api.nutriportal.eu/filemaker/${(currentPractice?.id || 0)}/create_nutrimail${window.location.search}`} />} />
    </Routes> */}

    {(!!login || (!login && !!getCookie('nutriportal_token'))) && <SharedLayout version="0.0.1" loginId={login?.id} practice={currentPractice}>
      <Routes>
        {/* Deze routes bestaan om de refresh te laten renderen vooraleer login gevonden wordt */}
        {!is_client && !is_coach && !is_admin && <Route path='/news' element={<></>} />}
        {!is_client && !is_coach && !is_admin && <Route path='/news/:id' element={<></>} />}

        {!is_client && !is_coach && !is_admin && <Route path='/bugs' element={<></>} />}
        {!is_client && !is_coach && <Route path='/inbox' element={<></>} />}
        {!is_client && !is_coach && <Route path='/inbox/new' element={<></>} />}
        {!is_client && !is_coach && <Route path='/meeting' element={<></>} />}
        {!is_client && !is_coach && <Route path='/meeting/:id' element={<></>} />}
        {!is_client && !is_coach && <Route path='/settings' element={<></>} />}
        {!is_client && !is_coach && <Route path='/workshops' element={<></>} />}
        {!is_client && !is_coach && <Route path='/appointments' element={<></>} />}
        {!is_client && !is_coach && <Route path='/questionnaires' element={<></>} />}

        {!is_coach && !is_admin && <Route path='/merge_requests' element={<></>} />}
        {!is_coach && !is_admin && <Route path='/news_items' element={<></>} />}
        {!is_coach && !is_admin && <Route path='/news_item/:id' element={<></>} />}

        {!is_client && <Route path='/diary' element={<></>} />}
        {!is_client && <Route path='/diary_week' element={<></>} />}
        {!is_client && <Route path='/intake' element={<></>} />}
        {!is_client && <Route path='/wake' element={<></>} />}
        {!is_client && <Route path='/weight' element={<></>} />}
        {!is_client && <Route path='/find' element={<></>} />}
        {!is_client && <Route path='/practice' element={<></>} />}
        {!is_client && <Route path='/appointment' element={<></>} />}
        {!is_client && <Route path='/q/:id' element={<></>} />}

        {!is_coach && <Route path='/agenda' element={<></>} />}
        {!is_coach && <Route path='/ehealth_inbox' element={<></>} />}
        {!is_coach && <Route path='/ehealth_inbox/new' element={<></>} />}
        {!is_coach && <Route path='/exports' element={<></>} />}
        {!is_coach && <Route path='/export/:id' element={<></>} />}
        {!is_coach && <Route path='/logs' element={<></>} />}
        {!is_coach && <Route path='/agenda_override/:id' element={<></>} />}
        {!is_coach && <Route path='/general_settings' element={<></>} />}
        {!is_coach && <Route path='/questionnaire/:id' element={<></>} />}
        {!is_coach && <Route path='/questionnaire/:id/subs' element={<></>} />}
        {!is_coach && <Route path='/users' element={<></>} />}
        {!is_coach && <Route path='/user/:id/appointments' element={<></>} />}
        {!is_coach && <Route path='/user/:id/detail' element={<></>} />}
        {!is_coach && <Route path='/user/detail' element={<></>} />}
        {!is_coach && <Route path='/user/:id/diary' element={<></>} />}
        {!is_coach && <Route path='/user/:id/file' element={<></>} />}
        {!is_coach && <Route path='/user/:id/measures' element={<></>} />}
        {!is_coach && <Route path='/user/:id/merge_request' element={<></>} />}
        {!is_coach && <Route path='/user/:id' element={<></>} />}
        {!is_coach && <Route path='/workshop/:id' element={<></>} />}
        {!is_coach && <Route path='/workshop/:id/subscribers' element={<></>} />}

        {!is_admin && <Route path='/coach_skills' element={<></>} />}
        {!is_admin && <Route path='/coach_skill/:id' element={<></>} />}
        {!is_admin && <Route path='/countries' element={<></>} />}
        {!is_admin && <Route path='/country/:id' element={<></>} />}
        {!is_admin && <Route path='/email_templates' element={<></>} />}
        {!is_admin && <Route path='/email_template/:id' element={<></>} />}
        {!is_admin && <Route path='/imports' element={<></>} />}
        {!is_admin && <Route path='/languages' element={<></>} />}
        {!is_admin && <Route path='/language/:id' element={<></>} />}
        {!is_admin && <Route path='/modules' element={<></>} />}
        {!is_admin && <Route path='/da_module/:id' element={<></>} />}
        {!is_admin && <Route path='/practices' element={<></>} />}
        {!is_admin && <Route path='/practice/:id' element={<></>} />}
        {!is_admin && <Route path='/practice/:id/theming' element={<></>} />}
        {!is_admin && <Route path='/practice_types' element={<></>} />}
        {!is_admin && <Route path='/practice_type/:id' element={<></>} />}
        {!is_admin && <Route path='/sms' element={<></>} />}
        {!is_admin && <Route path='/statics' element={<></>} />}
        {!is_admin && <Route path='/static/:id' element={<></>} />}
        {!is_admin && <Route path='/user_page' element={<></>} />}


        {(is_client || is_coach) && <Route path='/bugs' element={<BugTracker />} />}


        {is_client && <Route path='/' element={<ClientDashboard />} />}
        {is_client && <Route path='/diary' element={<ClientDiary />} />}
        {is_client && <Route path='/diary_week' element={<ClientDiaryWeek />} />}
        {is_client && <Route path='/intake' element={<ClientIntake />} />}
        {is_client && <Route path='/wake' element={<ClientMeasuresWake />} />}
        {is_client && <Route path='/weight' element={<ClientMeasuresWeight />} />}
        {is_client && <Route path='/find' element={<ClientPracticeFindPractice />} />}
        {is_client && <Route path='/practice' element={<ClientPracticeMyPractice />} />}
        {is_client && <Route path='/appointment' element={<ClientAppointmentNew />} />}
        {is_client && <Route path='/appointments' element={<ClientAppointmentList />} />}
        {is_client && <Route path='/q/:id' element={<ClientQuestionnaireEdit />} />}
        {is_client && <Route path='/workshops' element={<ClientAppointmentWorkshops />} />}
        {is_client && !is_coach && <Route path='/inbox' element={<NutriMail />} />}
        {is_client && <Route path='/inbox/new' element={<ClientEditNutriMail />} />}
        {is_client && !is_coach && <Route path='/questionnaires' element={<ClientQuestionnaires />} />}
        {is_client && <Route path='/settings' element={<ClientSettings />} />}
        {is_client && !is_coach && <Route path='/meeting' element={<SharedMeeting />} />}
        {is_client && !is_coach && <Route path='/meeting/:id' element={<SharedMeeting />} />}
        {is_client && !is_coach && !is_admin && <Route path='/news' element={<ClientNews />} />}
        {is_client && !is_coach && !is_admin && <Route path='/news/:id' element={<SharedNewsItem />} />}


        {is_coach && <Route path='/' element={<CoachDashboard />} />}
        {is_coach && <Route path='/agenda' element={<CoachAgenda />} />}
        {is_coach && <Route path='/ehealth_inbox' element={<EhealthMail />} />}
        {is_coach && <Route path='/ehealth_inbox/new' element={<CoachEditMail />} />}
        {is_coach && <Route path='/exports' element={<CoachExports />} />}
        {is_coach && <Route path='/export/:id' element={<CoachExportEdit />} />}
        {is_coach && <Route path='/general_settings' element={<CoachGeneralSettings />} />}
        {!is_client && is_coach && <Route path='/inbox' element={<NutriMail />} />}
        {is_coach && <Route path='/inbox/new' element={<CoachEditNutriMail />} />}
        {is_coach && <Route path='/logs' element={<CoachLogs />} />}
        {is_coach && <Route path='/merge_requests' element={<CoachUserMergeRequests />} />}
        {is_coach && !is_admin && <Route path='/news_items' element={<CoachNewsItems />} />}
        {is_coach && !is_admin && <Route path='/news_item/:id' element={<CoachNewsItemEdit />} />}
        {is_coach && <Route path='/agenda_override/:id' element={<CoachOverrideEdit />} />}
        {is_coach && <Route path='/settings' element={<CoachSettings />} />}
        {is_coach && <Route path='/questionnaires' element={<CoachQuestionnaires />} />}
        {is_coach && <Route path='/questionnaire/:id' element={<CoachQuestionnaireEdit />} />}
        {is_coach && <Route path='/questionnaire/:id/subs' element={<CoachQuestionnaireSubs />} />}
        {is_coach && <Route path='/users' element={<CoachUsers />} />}
        {is_coach && <Route path='/user/:id/appointments' element={<CoachUserAppointments />} />}
        {is_coach && <Route path='/appointments' element={<CoachFmUserAppointments />} />}
        {is_coach && <Route path='/user/:id/detail' element={<CoachUserDetail />} />}
        {is_coach && <Route path='/user/detail' element={<CoachFmUserDetail />} />}
        {is_coach && <Route path='/user/:id/diary' element={<CoachUserDiary />} />}
        {is_coach && <Route path='/user/:id/file' element={<CoachUserIntake />} />}
        {is_coach && <Route path='/user/:id/measures' element={<CoachUserMeasures />} />}
        {is_coach && <Route path='/user/:id/merge_request' element={<CoachUserMerge />} />}
        {is_coach && <Route path='/user/:id' element={<CoachUserEdit />} />}
        {is_coach && <Route path='/workshops' element={<CoachWorkshops />} />}
        {is_coach && <Route path='/workshop/:id' element={<CoachWorkshopEdit />} />}
        {is_coach && <Route path='/workshop/:id/subscribers' element={<CoachWorkshopSubscribers />} />}
        {!is_client && is_coach && <Route path='/meeting' element={<SharedMeeting coach />} />}
        {!is_client && is_coach && <Route path='/meeting/:id' element={<SharedMeeting coach />} />}
        {!is_client && is_coach && !is_admin && <Route path='/news' element={<CoachNews />} />}
        {!is_client && is_coach && !is_admin && <Route path='/news/:id' element={<SharedNewsItem coach />} />}


        {is_admin && <Route path='/' element={<AdminDashboard />} />}
        {is_admin && <Route path='/bugs' element={<AdminBugs />} />}
        {is_admin && <Route path='/bug/:id' element={<AdminBugEdit />} />}
        {is_admin && <Route path='/coach_skills' element={<AdminContentIndex object_type='coach_skill' />} />}
        {is_admin && <Route path='/coach_skill/:id' element={<AdminContentEdit object_type='coach_skill' return_path='/coach_skills' />} />}
        {is_admin && <Route path='/countries' element={<AdminContentIndex object_type='country' />} />}
        {is_admin && <Route path='/country/:id' element={<AdminContentEdit object_type='country' return_path='/countries' />} />}
        {is_admin && <Route path='/email_templates' element={<AdminEmailTemplates />} />}
        {is_admin && <Route path='/email_template/:id' element={<AdminEmailTemplateEdit />} />}
        {is_admin && <Route path='/imports' element={<AdminImports />} />}
        {is_admin && <Route path='/languages' element={<AdminContentIndex object_type='language' />} />}
        {is_admin && <Route path='/language/:id' element={<AdminContentEdit object_type='language' return_path='/languages' />} />}
        {is_admin && <Route path='/merge_requests' element={<AdminMergeRequests />} />}
        {is_admin && <Route path='/modules' element={<AdminContentIndex object_type='da_module' />} />}
        {is_admin && <Route path='/da_module/:id' element={<AdminContentEdit object_type='da_module' return_path='/modules' />} />}
        {is_admin && !is_coach && <Route path='/news_items' element={<AdminNewsItems />} />}
        {is_admin && !is_coach && <Route path='/news_item/:id' element={<AdminNewsItemEdit />} />}
        {is_admin && <Route path='/practices' element={<AdminPractices />} />}
        {is_admin && <Route path='/practice/:id' element={<AdminPracticeEdit />} />}
        {is_admin && <Route path='/practice/:id/theming' element={<AdminPracticeTheming />} />}
        {is_admin && <Route path='/practice_types' element={<AdminContentIndex object_type='practice_type' />} />}
        {is_admin && <Route path='/practice_type/:id' element={<AdminContentEdit object_type='practice_type' return_path='/practice_types' />} />}
        {is_admin && <Route path='/sms' element={<AdminSmsDashboard />} />}
        {is_admin && <Route path='/statics' element={<AdminContentIndex object_type='static' />} />}
        {is_admin && <Route path='/static/:id' element={<AdminContentEdit object_type='static' return_path='/statics' />} />}
        {is_admin && <Route path='/user_page' element={<AdminUserPage />} />}
        {!is_client && !is_coach && is_admin && <Route path='/news' element={<AdminNews />} />}
        {!is_client && !is_coach && is_admin && <Route path='/news/:id' element={<SharedNewsItem admin />} />}


        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </SharedLayout>}
    
    {!login && !getCookie('nutriportal_token') && <Routes>
      {/* <Route path='/' element={<Landing/>} /> */}
      <Route path='/login' element={<SharedOnboarding />} />
      <Route path='/register' element={<SharedOnboarding isRegister={true} />} />
      <Route path='/confirm/:token' element={<SharedConfirmLoader />} />
      <Route path='/impersonate/:token' element={<SharedImpersonateLoader />} />
      <Route path='/appointment' element={<ClientAppointmentNew />} />
      <Route path='/q/:id' element={<ClientQuestionnaireEdit />} />
      <Route path='/find' element={<SharedFind />} />

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>}
  </ThemeProvider>);
}

