import React, { FunctionComponent, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import {
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  MenuItem
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  Fab,
  FileUpload,
  IconButton,
  Page,
  Select,
  Typography,
} from '../../components/v2/styled';
import { SnackDestroyed } from '../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../components/v2/snacks/Saved';

import { Import } from '../../models/Import';
import { Practice } from '../../models/Practice';
import { User } from '../../models/User';

// const { DateTime } = require("luxon");

type Props = {}

export const AdminImports: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [newImport, setNewImport] = useState<Import>();
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["admin", "imports", {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Import>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'import',
          fields: ['id', 'extra_data', 'started_at', 'finished_at', 'practice|id/name', 'user|id/full_name'],
          order: order,
          per_page: 20,
          page: page,
          // filter: {
          //   search: '',
          //   advanced: {
          //     practice_id: (login?.practice_id || currentPractice?.id)
          //   }
          // }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id),
  });
  const {
    data: practices,
    isLoading: practicesLoading,
    isError: practicesError,
    isSuccess: practicesSuccess,
    refetch: practicesRefetch,
  } = useQuery({
    queryKey: ["practices", 'short_list'],
    queryFn: () =>
      fetch_all<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          fields: ['id', 'name'],
          page: 0,
          per_page: 1000,
          order: "name ASC"
        },
        login
      ),
    enabled: !!login,
  });
  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", newImport?.practice_id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: newImport?.practice_id,
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!newImport?.practice_id
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Import>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Import>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Import>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setNewImport(undefined);
      objectsRefetch();
    },
  });







  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'practice_name', headerName: t('admin.models.imports.practice'), width: 200 },
    { field: 'user_full_name', headerName: t('admin.models.imports.user'), width: 200 },
    { field: 'created_at', headerName: t('admin.models.imports.created_at'), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'started_at', headerName: t('admin.models.imports.started_at'), width: 200, renderCell: params => <>
      {!!params.row.started_at && <Dt d={params.row.started_at} f="DDD t" />}
    </> },
    { field: 'progress', headerName: t('admin.models.imports.progress'), width: 200, renderCell: params => <>
      <Typography>{params.row.extra_data.progress || 0}%</Typography>
      <LinearProgress variant='determinate' value={params.row.extra_data.progress || 0} />
    </> },
    { field: 'finished_at', headerName: t('admin.models.imports.finished_at'), width: 200, renderCell: params => <>
      {!!params.row.finished_at && <Dt d={params.row.finished_at} f="DDD t" />}
    </> },
  ];

  return <Page title={t("admin.menu.imports", "Imports")}>
    {!!newImport && <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            fullWidth
            caption={t("admin.models.imports.practice")}
            starred
            displayEmpty
            backend
            id="practice"
            value={newImport.practice_id || ''}
            setValue={(v) => {
              setNewImport({
                ...newImport,
                practice_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">------</MenuItem>
            {(practices || []).map(practice => <MenuItem value={practice.id}>{practice.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            fullWidth
            caption={t("admin.models.imports.user")}
            starred
            displayEmpty
            backend
            id="coach"
            value={newImport.user_id || ''}
            setValue={(v) => {
              setNewImport({
                ...newImport,
                user_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">------</MenuItem>
            {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <FileUpload
            caption={t("admin.models.imports.item_apptypes")}
            fileInputName='item_apptypes'
            selected={newImport.item_appointment_types}
            onChange={(f) => {
              setNewImport({
                ...newImport,
                item_appointment_types: f as File
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FileUpload
            caption={t("admin.models.imports.item_clients")}
            fileInputName='item_clients'
            selected={newImport.item_clients}
            onChange={(f) => {
              setNewImport({
                ...newImport,
                item_clients: f as File
              });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FileUpload
            caption={t("admin.models.imports.item_appointments")}
            fileInputName='item_appointments'
            selected={newImport.item_appointments}
            onChange={(f) => {
              setNewImport({
                ...newImport,
                item_appointments: f as File
              });
            }}
          />
        </Grid>

        
        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={!newImport.practice_id || !newImport.user_id || !newImport.item_appointment_types || !newImport.item_appointments || !newImport.item_clients}
            contained
            onClick={(v) => {
              let data = new FormData();
              data.append('object', 'import');
              data.append('handler_id', login?.id?.toString() || '');
              data.append('ob[practice_id]', newImport.practice_id?.toString() || '');
              data.append('ob[user_id]', newImport.user_id?.toString() || '');
              if (!!newImport.item_appointment_types) {
                data.append(`ob[item_appointment_types]`, newImport.item_appointment_types);
              }
              if (!!newImport.item_clients) {
                data.append(`ob[item_clients]`, newImport.item_clients);
              }
              if (!!newImport.item_appointments) {
                data.append(`ob[item_appointments]`, newImport.item_appointments);
              }
              
              mutationSave.mutate({
                formData: data
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              setNewImport(undefined);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </>}

    {!newImport && <DataGrid
      action_count={2}
      actions={(params) => {
        return (<>
          <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'import',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
          {!params.row.started_at && <IconButton title="Run it" color="default" onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'import',
                class_action: 'custom_api_run_it',
                id: params.row.id
              }
            });
          }}>
            <DirectionsRunIcon />
          </IconButton>}
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    {!newImport && <Fab
      onClick={() => {
        // navigate("/export/new", {});
        setNewImport({});
      }}
    />}
  </Page>;
}
