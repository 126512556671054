import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
// import { currentPracticeAtom } from '../../../lib/practice';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
  Alert,
  CircularProgress,
  Divider,
  Grid,
  Paper
} from '@mui/material';

import {
  Button,
  ColourPicker,
  ImagePicker,
  Page,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Practice } from '../../../models/Practice';

// const { DateTime } = require("luxon");

type Props = {};

export const AdminPracticeTheming: FunctionComponent<Props> = () => {
  const [login, setLogin] = useAtom(loginAtom);
  // const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Practice>({
    name: '',
    coaches: [],
    coaches_active_not_secretary: [],
    locations: [],
    locations_active: [],
    locations_true_active: [],
    extra_data: {
      custom_theme: {
        primary_main: 'rgba(151, 190, 107, 1)',
        contrast_main: 'rgba(255, 255, 255, 1)',
        tertiary_main: 'rgba(151, 190, 107, 1)'
      }
    }
  });

  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ['admin', "practice", objectId],
    queryFn: () =>
      fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'practice',
          id: objectId,
          fields: [
            'id', 'name', 'extra_data', 'get_onboarding_bg', 'module_ids'
          ],
        },
        login
      ),
    enabled: !!login && parseInt(objectId || '', 10) > 0,
  });








  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Practice>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["admin", "practices"] });
      if (!variables.keepOpen) navigate("/practices");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/practice/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });










  const saveObject = (keep_open: boolean) => {
    let formData = new FormData();
    formData.append('object', 'practice');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    if (!!objectId && objectId !== 'new') formData.append('ob[id]', objectId.toString());
    if (!!objectId && objectId !== 'new') formData.append('id', objectId.toString());
    // mainObject
    formData.append('ob[extra_data][custom_theme][primary_main]', mainObject?.extra_data?.custom_theme?.primary_main || '');
    formData.append('ob[extra_data][custom_theme][primary_main_dark]', mainObject?.extra_data?.custom_theme?.primary_main_dark || '');
    formData.append('ob[extra_data][custom_theme][primary_main_light]', mainObject?.extra_data?.custom_theme?.primary_main_light || '');
    formData.append('ob[extra_data][custom_theme][tertiary_main]', mainObject?.extra_data?.custom_theme?.tertiary_main || '');
    formData.append('ob[extra_data][custom_theme][tertiary_main_dark]', mainObject?.extra_data?.custom_theme?.tertiary_main_dark || '');
    formData.append('ob[extra_data][custom_theme][tertiary_main_light]', mainObject?.extra_data?.custom_theme?.tertiary_main_light || '');
    formData.append('ob[extra_data][custom_theme][text_colour]', mainObject?.extra_data?.custom_theme?.text_colour || '');
    formData.append('ob[extra_data][custom_theme][contrast_main]', mainObject?.extra_data?.custom_theme?.contrast_main || '');
    if (!!item) formData.append(`ob[onboarding_bg]`, item);
    if (!!itemUrl) formData.append(`ob[onboarding_bg_url]`, itemUrl);

    mutationSave.mutate({
      formData: formData,
      keepOpen: keep_open
    });
  };

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    console.log('change');
  }, [mainObject]);

  return (
    <Page sx={{}} title={`${mainObject?.name} theming`}>
      <Paper sx={{padding: 2}}>
        <Grid container spacing={1}>
          {(mainObject?.module_ids || []).indexOf(18) < 0 && <Grid item xs={12}>
            <Alert severity="warning" sx={{border: '1px solid transparent', borderColor: theme.palette.warning.main}}>Deze praktijk heeft geen Custom Theme module!!</Alert>
          </Grid>}
          <Grid item xs={12}>
            <Alert severity="success" sx={{border: '1px solid transparent', borderColor: theme.palette.primary.main}}>https://mui.com/material-ui/customization/color/</Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{fontSize: '0.8rem'}}>Onboarding BG</Typography>
            <ImagePicker existing={mainObject?.get_onboarding_bg} item={item} itemUrl={itemUrl} onSelect={(_item:File|undefined, _itemUrl:string|undefined) => {
              setItem(_item);
              setItemUrl(_itemUrl);
            }} />
          </Grid>
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.primary_main", "Hoofdkleur")}
              value={mainObject?.extra_data?.custom_theme?.primary_main || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                if (v !== 'rgba(151, 190, 107, 1)') setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      primary_main: v
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.primary_main", "Hoofdkleur Dark")}
              value={mainObject?.extra_data?.custom_theme?.primary_main_dark || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                if (v !== 'rgba(151, 190, 107, 1)') setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      primary_main_dark: v
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.primary_main_light", "Hoofdkleur Light")}
              value={mainObject?.extra_data?.custom_theme?.primary_main_light || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                if (v !== 'rgba(151, 190, 107, 1)') setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      primary_main_light: v
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.contrast_main", "Hoofdkleur - contrast text")}
              value={mainObject?.extra_data?.custom_theme?.contrast_main || 'rgba(255, 255, 255, 1)'}
              onChange={(v) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      contrast_main: v
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.text_colour", "Tekstkleur")}
              value={mainObject?.extra_data?.custom_theme?.text_colour || 'rgba(255, 255, 255, 1)'}
              onChange={(v) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      text_colour: v
                    }
                  }
                });
              }}
            />
          </Grid>
          {/* <Grid item xs={4}></Grid> */}
          <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.tertiary_main", "Detailkleur")}
              value={mainObject?.extra_data?.custom_theme?.tertiary_main || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                if (v !== 'rgba(151, 190, 107, 1)') setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      tertiary_main: v
                    }
                  }
                });
              }}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.tertiary_main", "Detailkleur Dark")}
              value={mainObject?.extra_data?.custom_theme?.tertiary_main_dark || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      tertiary_main_dark: v
                    }
                  }
                });
              }}
            />
          </Grid> */}
          {/* <Grid item xs={4}>
            <ColourPicker
              label={t("admin.models.practices.tertiary_main", "Detailkleur Light")}
              value={mainObject?.extra_data?.custom_theme?.tertiary_main_light || 'rgba(151, 190, 107, 1)'}
              onChange={(v) => {
                setMainObject({
                  ...mainObject,
                  extra_data: {
                    ...(mainObject?.extra_data || {}),
                    // @ts-ignore
                    custom_theme: {
                      ...(mainObject?.extra_data?.custom_theme || {}),
                      tertiary_main_light: v
                    }
                  }
                });
              }}
            />
          </Grid> */}


          







          <Grid item xs={12} sx={{
            alignContent: 'baseline',
            textAlign: 'center',
            position: {
              xs: 'relative',
              md: 'sticky'
            },
            bottom: '-24px',
            backgroundColor: 'white',
            zIndex: 1201
          }}>
            <Divider sx={{marginTop: 1, marginBottom: 0}} />

            {!!mutationSave.isPending && <CircularProgress />}
            {!mutationSave.isPending && <Button
              label={t("shared.buttons.save")}
              id='save'
              contained
              onClick={(v) => {
                saveObject(false);
              }}
              sx={{marginBottom: 1, marginTop: 1}}
            />}
            {!mutationSave.isPending && <Button
              label={t("shared.buttons.cancel")}
              id='cancel'
              text
              // contained
              onClick={(v) => {
                navigate("/practices");
              }}
              sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
            />}
          </Grid>
        </Grid>
      </Paper>

      <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
    </Page>
  );
}
