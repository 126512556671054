import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';

import {
  Box,
  Button as Btn,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Editor,
  IconButton,
  ImagePicker,
  Page,
  Select,
  Switcher,
  TextInput,
  TimePickerNew,
  Typography
} from '../../../components/v2/styled';
import { Base } from '../../../components/v2/dialogs/Base';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Appointment } from '../../../models/Appointment';
import { Location } from '../../../models/Location';
import { Override } from '../../../models/Override';
import { User } from '../../../models/User';

// import HelpersPractice from '../../../actions/helpers/practice';

const { Interval, DateTime } = require("luxon");

type Props = {}

export const CoachOverrideEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  const { state } = useLocation();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Override>({
    user_id: !!state && !!state.user_id ? state.user_id : undefined,
    start_date: !!state && !!state.time ? state.time : undefined,
    end_date: !!state && !!state.time ? state.time : undefined,
    day_overrides: !!state && !!state.day_overrides ? state.day_overrides: undefined
  });
  const [slotEditOpen, setSlotEditOpen] = useState<boolean>(false);
  const [activeSlot, setActiveSlot] = useState<{
    index?: number;
      start_hour?: number;
      start_minute?: number;
      end_hour?: number;
      end_minute?: number;
      location?: {
        value?: number;
        label?: string;
      };
      day?: string;
  }>({});
  let appCount = 0;

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: appointments,
    isLoading: appointmentsLoading,
    isError: appointmentsError,
    isSuccess: appointmentsSuccess,
    refetch: appointmentsRefetch,
  } = useQuery({
    queryKey: ["appointments_between", currentPractice?.id, {user: mainObject.user_id, start_date: mainObject.start_date, end_date: mainObject.end_date}],
    queryFn: () =>
      fetch_all_with_count<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: ['id'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              from: DateTime.fromFormat(mainObject.start_date, "dd/LL/y").set({hour: 0, minute: 0, second: 0}).toFormat("y-LL-dd t"),
              to: DateTime.fromFormat(mainObject.end_date, "dd/LL/y").set({hour: 23, minute: 59, second: 59}).toFormat("y-LL-dd t"),
              coach_id: mainObject.user_id
            }
          }
        },
        login
      ),
    select: (d) => {
      appCount = d.count;
      return d.result;
    },
    enabled: !!currentPractice?.id && !!mainObject.start_date && !!mainObject.end_date
  });
  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'colour'],
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["override", (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<Override>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'override',
          id: objectId,
          fields: [
            'id', 'start_date', 'end_date', 'is_vacation', 'vacation_comment', 'day_overrides', 'user_id'
          ]
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        start_date: DateTime.fromISO(d.start_date).toFormat("dd/LL/y"),
        end_date: DateTime.fromISO(d.end_date).toFormat("dd/LL/y"),
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Override>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "overrides", (login?.practice_id || currentPractice?.id)] });
      if (!variables.keepOpen) {
        if (!!state && !!state.time) {
          navigate("/agenda", {state: {day: state.time}});
        } else {
          navigate("/settings", {state: {tab: 2}});
        }
      }
      if (!!variables.keepOpen && objectId === 'new') navigate(`/override/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    mutationSave.mutate({
      formData: {
        object: 'override',
        fields: ['id'],
        handler_id: login?.id,
        id: objectId,
        ob: {
          is_vacation: false,
          ...mainObject,
          practice_id: (login?.practice_id || currentPractice?.id)
        }
      },
      keepOpen: keep_open
    });
  };

  let term = t('coach.models.overrides.table_title');
  let termSingle = t("coach.models.overrides.singular", "Agenda uitzondering");

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = t("general.titles.new", "Nieuwe") + " " + termSingle;
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + termSingle;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.user_id) err.user_id = true;
    if (!mainObject.start_date) err.start_date = true;
    if (!mainObject.end_date) err.end_date = true;
    if (!!mainObject.start_date && !!mainObject.end_date && DateTime.fromFormat(mainObject.start_date, "dd/LL/y") > DateTime.fromFormat(mainObject.end_date, "dd/LL/y")) err.start_date = true;
    if (!!mainObject.start_date && !!mainObject.end_date && Interval.fromDateTimes(DateTime.fromFormat(mainObject.start_date, "dd/LL/y").startOf("day"), DateTime.fromFormat(mainObject.end_date, "dd/LL/y").endOf("day")).splitBy({ day: 1 }).filter(
      // @ts-ignore
      day => ((mainObject.day_overrides || [])[day.start.toFormat("y-LL-dd")] || []).filter(
        // @ts-ignore
        fff => ((mainObject.day_overrides || [])[day.start.toFormat("y-LL-dd")] || []).filter(
          // @ts-ignore
          ggg => fff.index !== ggg.index
        ).filter(
          // @ts-ignore
          ggg => (
            DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) >= DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && 
            DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) < DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})
          ) || (
            DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) > DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && 
            DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) <= DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})
          )
        ).length > 0
      ).length > 0
    ).length > 0) err.day_overrides = true;
    
    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: term,
        path: "/settings",
        state: {tab: 2}
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          <Select
            fullWidth
            caption={t("coach.models.default_weeks.coach", "Coach")}
            starred
            displayEmpty
            backend
            id="coach"
            value={mainObject.user_id || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                user_id: !!v ? parseInt(v.toString(), 10) : undefined
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.default_weeks.placeholders.coach", "Vul hier de coach in")} ---</MenuItem>
            {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography sx={{
            fontSize: '0.8rem'
          }}>{t("coach.models.agenda_overrides.start_date", "Startdatum")} *</Typography>
          <DatePicker
            openTo="day"
            disablePast
            label=""
            format={"dd/LL/y"}
            // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                start_date: e
              });
            }}
            value={mainObject.start_date || ''}
            noIcon
            id={`start_date`}
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                },
              }
            }}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <Typography sx={{
            fontSize: '0.8rem'
          }}>{t("coach.models.agenda_overrides.end_date", "Einddatum")} *</Typography>
          <DatePicker
            openTo="day"
            disablePast
            label=""
            format={"dd/LL/y"}
            // caption={t("client.appointment.option_start_date", "Welke dag draagt je voorkeur?")}
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                end_date: e
              });
            }}
            value={mainObject.end_date || ''}
            noIcon
            id={`end_date`}
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                },
              }
            }}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <Switcher
            id="is_vacation"
            caption={t("coach.models.agenda_overrides.is_vacation", "Is vakantie")}
            value={!!mainObject?.is_vacation}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                is_vacation: c
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{fontStyle: 'italic'}}>{t("coach.models.agenda_overrides.appointments_during_period", "Afspraken tijdens deze periode:")} {appCount || 0}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="vacation_comment"
            caption={t("coach.models.agenda_overrides.vacation_comment", "Opmerking")}
            placeholder={t("coach.models.agenda_overrides.placeholders.vacation_comment", "Vul hier een eventuele opmerking in")}
            multiline
            value={mainObject.vacation_comment || ''}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                vacation_comment: e
              });
            }}
          />
        </Grid>

        {!mainObject.is_vacation && <>
          <Grid item xs={12} sx={{marginTop: 2}}>
              {/* @ts-ignore */}
            {!!mainObject.start_date && !!mainObject.end_date && Interval.fromDateTimes(DateTime.fromFormat(mainObject.start_date, "dd/LL/y").startOf("day"), DateTime.fromFormat(mainObject.end_date, "dd/LL/y").endOf("day")).splitBy({ day: 1 }).map(day => <Box sx={{
              width: '14.2%',
              minWidth: '120px',
              border: '1px solid transparent',
              borderColor: theme.palette.primary.main,
              display: 'inline-block',
              padding: 0.5,
              height: '100%',
              float: 'left',
              textAlign: 'center'
            }}>
              <Typography>
                {day.start.toFormat("dd/LL/y")}
                {/* @ts-eignore */}
                {((mainObject.day_overrides || {})[day.start.toFormat("y-LL-dd")] || []).filter(fff => ((mainObject.day_overrides || {})[day.start.toFormat("y-LL-dd")] || []).filter(ggg => fff.index !== ggg.index).filter(ggg => (DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) >= DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && DateTime.now().set({hour: fff.start_hour, minute: fff.start_minute}) < DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute})) || (DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) > DateTime.now().set({hour: ggg.start_hour, minute: ggg.start_minute}) && DateTime.now().set({hour: fff.end_hour, minute: fff.end_minute}) <= DateTime.now().set({hour: ggg.end_hour, minute: ggg.end_minute}))).length > 0).length > 0 && <WarningIcon sx={{color: theme.palette.warning.main, marginBottom: -0.75, marginLeft: 1}} />}
              </Typography>
              <Divider />
              {/* @ts-ignore */}
              {((mainObject.day_overrides || [])[day.start.toFormat("y-LL-dd")] || []).map(slot => <Chip
                label={[
                  DateTime.now().set({hour: slot.start_hour, minute: slot.start_minute}).toFormat("t"),
                  DateTime.now().set({hour: slot.end_hour, minute: slot.end_minute}).toFormat("t")
                ].join(" - ")}
                sx={{
                  margin: 0.5
                }}
                color="primary"
                onClick={() => {
                  setActiveSlot({
                    ...slot,
                    day: day.start.toFormat("dd/LL/y")
                  });
                  setSlotEditOpen(true);
                }}
                onDelete={() => {
                  let newDayOverridesForDay = (mainObject.day_overrides || {})[day.start.toFormat("y-LL-dd")];
                  newDayOverridesForDay = [...(newDayOverridesForDay || []).filter(fff => fff.index !== slot.index)];

                  setMainObject({
                    ...mainObject,
                    day_overrides: {
                      ...(mainObject.day_overrides || {}),
                      [day.start.toFormat("y-LL-dd")]: newDayOverridesForDay
                    }
                  });
                }}
              />)}
              <Divider />
              <Box sx={{width: '100%', paddingTop: 0.5}}>
                <IconButton
                  onClick={() => {
                    setActiveSlot({
                      day: day.start.toFormat("dd/LL/y"),
                      start_hour: 9,
                      start_minute: 0,
                      end_hour: 17,
                      end_minute: 0
                    });
                    setSlotEditOpen(true);
                  }}
                  sx={{color: theme.palette.primary.main}}
                ><AddIcon /></IconButton>
              </Box>
            </Box>)}
          </Grid>
        </>}

        







        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              if (!!state && !!state.time) {
                navigate("/agenda", {state: {day: state.time}});
              } else {
                navigate("/settings", {state: {tab: 2}});
              }
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Base
      name="setSlot"
      hidden={!slotEditOpen}
      title={t("coach.submenu.personal_calendar_settings.add_block")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.default_weeks.location", "Locatie")}
            displayEmpty
            id="location"
            value={activeSlot?.location?.value || ''}
            setValue={(v) => {
              setActiveSlot({
                ...activeSlot,
                location: {
                  value: !!v ? parseInt(v.toString(), 10) : undefined,
                  label: ((locations || []).filter(fff => fff.id === (!!v ? parseInt(v.toString(), 10) : undefined))[0] || {}).name
                }
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.default_weeks.placeholders.location", "Vul hier de locatie in")} ---</MenuItem>
            {(locations || []).map(location => <MenuItem value={location.id}>{location.name}</MenuItem>)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.default_weeks.start_hour", "Start-uur")}</Typography>
          <TimePickerNew
            id="time_start"
            value={DateTime.now().set({hour: activeSlot.start_hour || 9, minute: activeSlot.start_minute || 0}).toFormat("t")}
            setValue={(e) => {
              let dt = DateTime.fromFormat(e, "t");
              setActiveSlot({
                ...activeSlot,
                start_hour: dt.hour,
                start_minute: dt.minute
              });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.default_weeks.end_hour", "Eind-uur")}</Typography>
          <TimePickerNew
            id="time_end"
            value={DateTime.now().set({hour: activeSlot.end_hour || 17, minute: activeSlot.end_minute || 0}).toFormat("t")}
            setValue={(e) => {
              let dt = DateTime.fromFormat(e, "t");
              setActiveSlot({
                ...activeSlot,
                end_hour: dt.hour,
                end_minute: dt.minute
              });
            }}
          />
        </Grid>
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setSlotEditOpen(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn disabled={
          !activeSlot.location?.value || 
          !activeSlot.start_hour?.toString() || 
          !activeSlot.start_minute?.toString() || 
          !activeSlot.end_hour?.toString() || 
          !activeSlot.end_minute?.toString() ||
          DateTime.now().set({hour: activeSlot.start_hour || 9, minute: activeSlot.start_minute || 0}) > DateTime.now().set({hour: activeSlot.end_hour || 17, minute: activeSlot.end_minute || 0})
        } onClick={(e) => {
          let activeDoDay = DateTime.fromFormat(activeSlot.day, "dd/LL/y").toFormat("y-LL-dd");
          let newDayOverridesForDay = (mainObject.day_overrides || {})[activeDoDay];
          if (!!activeSlot.index?.toString()) newDayOverridesForDay = [...(newDayOverridesForDay || []).filter(fff => fff.index !== activeSlot.index)];

          setMainObject({
            ...mainObject,
            day_overrides: {
              ...(mainObject.day_overrides || {}),
              [activeDoDay]: [
                ...(newDayOverridesForDay || []),
                {
                  ...activeSlot,
                  index: (((mainObject.day_overrides || {})[activeDoDay]) || []).length
                }
              ]
            }
          });

          setSlotEditOpen(false);
          // add slot to weekday
        }}>{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={slotEditOpen}
      setOpen={setSlotEditOpen}
    />
  </Page>;
}
