import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HikingIcon from '@mui/icons-material/Hiking';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StarBorderPurple500 from '@mui/icons-material/StarBorderPurple500';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Typography
} from '../../../components/v2/styled';
import { EatMoment } from '../../../components/v2/balls/EatMoment';
import { EatMomentOverview } from '../../../components/v2/dialogs/EatMomentOverview';
import { MoveMomentOverview } from '../../../components/v2/dialogs/MoveMomentOverview';
import { MoveMoment } from '../../../components/v2/balls/MoveMoment';
import { EatDayOverview } from '../../../components/v2/dialogs/EatDayOverview';
import { MoveDayOverview } from '../../../components/v2/dialogs/MoveDayOverview';
import { EatWeekOverview } from '../../../components/v2/dialogs/EatWeekOverview';
import { Premium } from '../../../components/v2/dialogs/Premium';

import { DiaryDay } from '../../../models/DiaryDay';
import { EatMoment as EatMmnt } from '../../../models/EatMoment';
import { MoveMoment as MoveMmnt } from '../../../models/MoveMoment';
import { User } from '../../../models/User';

import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserDiary: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  // const queryClient = useQueryClient();
  const { state } = useLocation();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [premiumDiaryEatOpen, setPremiumDiaryEatOpen] = useState<boolean>(false);
  const [premiumDiaryMoveOpen, setPremiumDiaryMoveOpen] = useState<boolean>(false);
  const [premiumMeasuresWeightOpen, setPremiumMeasuresWeightOpen] = useState<boolean>(false);

  const [currentDay, setCurrentDay] = useState<string>(!!state && !!state.day ? state.day : DateTime.now().startOf('week').toFormat("dd/LL/y"));

  let is_nutri_diary_eat = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_diary_eat'});
  let is_nutri_diary_move = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_diary_move'});
  let is_measures_weight = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_measures_weight'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", 'short_diary', (login?.practice_id || currentPractice?.id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'last_name', 'full_move_groups', 'full_eat_groups', 'consent_status'
          ],
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && parseInt(objectId || '', 10) > 0,
  });

  const {
    data: diaryDays,
    isLoading: diaryDaysLoading,
    isError: diaryDaysError,
    isSuccess: diaryDaysSuccess,
    refetch: diaryDaysRefetch,
  } = useQuery({
    queryKey: ["diary", "diary_week", objectId, currentDay],
    queryFn: () =>
      fetch_all<DiaryDay>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'diary_day',
          fields: [
            'id', 'day', 'wake_time', 'sleep_time', 'move_moment_list', 'breakfast', 'lunch', 'diner', 'snack1', 'snack2', 'snack3', 'weight', 'given_sleep', 'given_wake', 'feedbacks'
          ],
          per_page: 7,
          page: 0,
          filter: {
            advanced: {
              day: currentDay,
              week: 1,
              user_id: objectId
            }
          }
        },
        login
      ),
    select: (d) => {
      return d.reverse();
    },
    enabled: !!login && !!currentDay && !!objectId,
  });






  

  const [eatDayOverviewDay, setEatDayOverviewDay] = useState<DiaryDay>();
  const [eatDayOverviewOpen, setEatDayOverviewOpen] = useState<boolean>(false);

  const [moveDayOverviewDay, setMoveDayOverviewDay] = useState<DiaryDay>();
  const [moveDayOverviewOpen, setMoveDayOverviewOpen] = useState<boolean>(false);
  
  const [moveOpen, setMoveOpen] = useState<boolean>(false);
  const [moveMoment, setMoveMoment] = useState<MoveMmnt>();
  
  const [eatOpen, setEatOpen] = useState<boolean>(false);
  const [eatMoment, setEatMoment] = useState<EatMmnt>();

  const [eatWeekOverviewOpen, setEatWeekOverviewOpen] = useState<boolean>(false);
  
  useEffect(() => {
    if (!!login?.is_secretary) navigate("/");
  }, [login]);

  return <Page
    title={t("coach.menu.diary")}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      },
      {
        title: !!object?.first_name ? [object?.first_name, object?.last_name].join(" ") : 'Cliënt',
        path: `/user/${object?.id}/detail`
      }
    ]}
    premiumGatekeeping={!!is_nutri_diary_eat || !!is_nutri_diary_move}
    premiumMode='nutri_diary_eat'
  >
    <Grid container spacing={1}>
      <Grid item xs={6} sx={{
        textAlign: 'left'
      }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").minus({days: 7}).toFormat("dd/LL/y"));
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
        <DatePicker
          // placeholder="DD/MM/YY"
          // autoOk={true}
          disableFuture={false}
          openTo="year"
          label=""
          format={"dd/LL/y"}
          views={["year", "month", "day"]}
          setValue={(e) => {
            setCurrentDay(e);
            // this.setState({day: e}, () => {
            //   this.fetch_day();
            // });
          }}
          value={currentDay}
          id="option_date"
          sx={{
            box: {
              width: 'auto',
              display: 'inline-block'
            },
            field_box: {
              "svg": {
                display: 'none'
              }
            },
            field: {
              width: 'auto',
              marginTop: 0,
              "input": {
                textAlign: 'center'
              }
            }
          }}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            setCurrentDay(DateTime.fromFormat(currentDay, "dd/LL/y").plus({days: 7}).toFormat("dd/LL/y"));
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6} sx={{
        textAlign: 'right'
      }}>
        <Button
          fullwidth={false}
          onClick={(e) => {
            e.preventDefault();
            setEatWeekOverviewOpen(true);
          }}
          sx={{marginTop: 1}}
          id="open_week_overview"
          label={t("coach.diary.overviews_week", "Weekoverzicht")}
          startIcon={<ViewWeekIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      {diaryDaysLoading && <Grid item xs={12} sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Grid>}
      {diaryDaysSuccess && [0, 1, 2, 3, 4, 5, 6].map(i => {
        let date = DateTime.fromFormat(currentDay, "dd/LL/y").plus({day: i})
        let day = (diaryDays || []).filter(ff => DateTime.fromISO(ff.day).toFormat("dd/LL/y") === date.toFormat("dd/LL/y"))[0];

        let total_steps = 0;
        let total_km = 0.0;
        if (day && (day.move_moment_list || []).length > 0) {
          (day.move_moment_list || []).forEach(m => {
            if (parseInt(m.amount_steps, 10) > 0) total_steps += parseInt(m.amount_steps, 10);
            if (parseFloat(m.amount_km) > 0) total_km += parseFloat(m.amount_km);
          });
        }

        return <>
          <Grid container item xs={12} sx={{marginTop: 2}}>
            <Grid container item xs={12} spacing={2}>
              <Grid item md={2} xs={4} style={{paddingTop: 0, paddingBottom: 0, padding: 1}}>
                <Typography variant="h5" sx={{fontSize: "1.05rem"}}>{date.toFormat("cccc")}</Typography>
                <Typography variant="h5" sx={{fontSize: "1.25rem"}}>{date.toFormat("dd LLL")}</Typography>
                {!!day && (!!day.breakfast || !!day.snack1 || !!day.lunch || !!day.snack2 || !!day.diner || !!day.snack3) && <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!is_nutri_diary_eat) {
                      setEatDayOverviewDay(day);
                      setEatDayOverviewOpen(true);
                    } else {
                      setPremiumDiaryEatOpen(true);
                    }
                  }}
                  size="small"
                  sx={{padding: 0, minWidth: 30}}
                >
                  <RestaurantIcon />
                </IconButton>}
                {!!day && (day.move_moment_list || []).length > 0 && <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    if (!!is_nutri_diary_move) {
                      setMoveDayOverviewDay(day);
                      setMoveDayOverviewOpen(true);
                    } else {
                      setPremiumDiaryMoveOpen(true);
                    }
                  }}
                  size="small"
                  sx={{padding: 0, minWidth: 30}}
                >
                  <HikingIcon />
                </IconButton>}
              </Grid>
              {!day && <Grid item md={10} xs={8} sx={{paddingTop: 0, paddingBottom: 0, fontStyle: 'italic'}}>{t("coach.diary.no_data", "Geen data beschikbaar voor deze dag")}</Grid>}
              {!!day && <>
                <Grid item md={4} xs={8} style={{paddingTop: 0, paddingBottom: 0}}>
                  <Grid container spacing={0}>
                    {(day.move_moment_list || []).length > 0 && <Box sx={{marginLeft: -1, width: '100%'}}>
                      {(day.move_moment_list || []).map(mmoment => <MoveMoment isCoach mmoment={mmoment} onClick={() => {
                        if (!!is_nutri_diary_move) {
                          setMoveMoment(mmoment);
                          setMoveOpen(true);
                        } else {
                          setPremiumDiaryMoveOpen(true);
                        }
                      }} />)}  
                    </Box>}

                    {(!!day.breakfast || !!day.snack1 || !!day.lunch || !!day.snack2 || !!day.diner || !!day.snack3) && <Box sx={{marginLeft: -1, width: '100%'}}>
                      {!!day.breakfast && <EatMoment isCoach emoment={day.breakfast} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.breakfast);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>1</>} />}
                      {!!day.snack1 && <EatMoment isCoach emoment={day.snack1} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.snack1);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>2</>} />}
                      {!!day.lunch && <EatMoment isCoach emoment={day.lunch} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.lunch);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>3</>} />}
                      {!!day.snack2 && <EatMoment isCoach emoment={day.snack2} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.snack2);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>4</>} />}
                      {!!day.diner && <EatMoment isCoach emoment={day.diner} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.diner);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>5</>} />}
                      {!!day.snack3 && <EatMoment isCoach emoment={day.snack3} onClick={() => {
                        if (!!is_nutri_diary_eat) {
                          setEatMoment(day.snack3);
                          setEatOpen(true);
                        } else {
                          setPremiumDiaryEatOpen(true);
                        }
                      }} icon={<>6</>} />}
                    </Box>}
                  </Grid>
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center', position: 'relative'}}>
                  {!is_nutri_diary_move && (total_steps > 0 || total_km > 0) && <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 1201,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    height: '64px',
                    textAlign: 'center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    setPremiumDiaryMoveOpen(true);
                  }}>
                    <StarBorderPurple500 sx={{marginTop: 1, color: theme.palette.primary.main}} />
                  </Box>}

                  {total_steps > 0 && <Typography variant="h5" sx={{fontSize: "0.9rem"}}>{t("coach.diary.amount_steps", "Aantal stappen")}</Typography>}
                  {total_steps > 0 && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{!!is_nutri_diary_move ? total_steps : "XXXX"}</Typography>}
                  {total_km > 0 && <Typography variant="h5" sx={{fontSize: "0.9rem", marginTop: 1}}>{t("coach.diary.amount_km", "Aantal km")}</Typography>}
                  {total_km > 0 && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{!!is_nutri_diary_move ? total_km.toLocaleString(undefined, {maximumFractionDigits: 1}) : "XX.X"} km</Typography>}
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center'}}>
                  {day.sleep_time && <Typography variant="h5" sx={{fontSize: "0.9rem"}}>{t("coach.diary.sleep_at", "Gaan slapen om")}</Typography>}
                  {day.sleep_time && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{day.given_sleep}</Typography>}
                  {day.wake_time && <Typography variant="h5" sx={{fontSize: "0.9rem", marginTop: 1}}>{t("coach.diary.wake_at", "Wakker om")}</Typography>}
                  {day.wake_time && <Typography variant="h5" sx={{fontSize: "1.2rem"}}>{day.given_wake}</Typography>}
                </Grid>
                <Grid item md={2} xs={4} sx={{paddingTop: 0, paddingBottom: 0, textlign: 'center', position: 'relative'}}>
                  {!is_measures_weight && day.weight && !!object?.consent_status?.is_measure_weight && <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 1201,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    height: '64px',
                    textAlign: 'center',
                    cursor: 'pointer'
                  }} onClick={() => {
                    setPremiumMeasuresWeightOpen(true);
                  }}>
                    <StarBorderPurple500 sx={{marginTop: 1, color: theme.palette.primary.main}} />
                  </Box>}

                  {day.weight && !!object?.consent_status?.is_measure_weight && <Typography variant="h5" sx={{fontSize: "1.5rem", marginTop: 2}}>{!!is_measures_weight ? day.weight.toLocaleString(undefined, {maximumFractionDigits: 1}) : "XX.X"}kg</Typography>}
                </Grid>
              </>}
            </Grid>
          </Grid>
          {i < 6 && <Grid item xs={12}><Divider /></Grid>}
        </>;
      })}
      

    </Grid>

    {!!eatMoment && <EatMomentOverview
      open={eatOpen}
      setOpen={setEatOpen}
      eatMoment={eatMoment}
      refetch={() => {
        diaryDaysRefetch();
      }}
      consent={!!object?.consent_status?.is_diary_eat}
    />}
    {!!moveMoment && <MoveMomentOverview
      open={moveOpen}
      setOpen={setMoveOpen}
      moveMoment={moveMoment}
      refetch={() => {
        diaryDaysRefetch();
      }}
      consent={!!object?.consent_status?.is_diary_move}
    />}
    {!!eatDayOverviewDay && !!object && <EatDayOverview
      open={eatDayOverviewOpen}
      setOpen={setEatDayOverviewOpen}
      diaryDay={eatDayOverviewDay}
      refetch={() => {
        diaryDaysRefetch();
      }}
      user={object}
      consent={!!object?.consent_status?.is_diary_eat}
    />}
    {!!moveDayOverviewDay && !!object  && <MoveDayOverview
      open={moveDayOverviewOpen}
      setOpen={setMoveDayOverviewOpen}
      diaryDay={moveDayOverviewDay}
      refetch={() => {
        diaryDaysRefetch();
      }}
      user={object}
      consent={!!object?.consent_status?.is_diary_move}
    />}
    {!!diaryDays && !!object && <EatWeekOverview
      open={eatWeekOverviewOpen}
      setOpen={setEatWeekOverviewOpen}
      diaryDays={diaryDays}
      refetch={() => {
        diaryDaysRefetch();
      }}
      user={object}
      consent={!!object?.consent_status?.is_diary_eat}
    />}

    <Premium
      open={premiumMeasuresWeightOpen}
      setOpen={(b) => {
        setPremiumMeasuresWeightOpen(b);
      }}
      mode="measures_weight"
    />
    <Premium
      open={premiumDiaryEatOpen}
      setOpen={(b) => {
        setPremiumDiaryEatOpen(b);
      }}
      mode="nutri_diary_eat"
    />
    <Premium
      open={premiumDiaryMoveOpen}
      setOpen={(b) => {
        setPremiumDiaryMoveOpen(b);
      }}
      mode="nutri_diary_move"
    />
  </Page>;
}
