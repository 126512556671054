import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Button as Btn,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Button,
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { ClientPicker } from '../../../components/v2/ClientPicker';
import { CoachShowQuestionnaire } from '../../../components/v2/dialogs/CoachShowQuestionnaire';
import { SearchBox } from '../../../components/v2/SearchBox';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { QuestionnaireResult } from '../../../models/QuestionnaireResult';

import HelpersPractice from '../../../actions/helpers/practice';
import { Base } from '../../../components/v2/dialogs/Base';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachQuestionnaireSubs: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");
  const [search, setSearch] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [qResultOpen, setQResultOpen] = useState<boolean>(false);
  const [activeQResult, setActiveQResult] = useState<number>();
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<QuestionnaireResult>({
    questionnaire_id: objectId,
    practice_id: currentPractice?.id,
    do_invite: true
  });

  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);

  const is_questionnaire = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'questionnaire'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["questionnaire_result", (login?.practice_id || currentPractice?.id), activeQResult],
    queryFn: () =>
      fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          id: activeQResult,
          fields: [
            'id', 'name', 'extra_data', 'questionnaire', 'practice', 'comment', 'is_locked', 'is_frontend'
          ],
          sub_fields: {
            'practice': ['id', 'name'],
            'questionnaire': ['id', 'name', 'extra_data', 'content_type']
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    // select: (d) => {
    //   return {
    //     ...d,
    //     birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
    //   };
    // },
    enabled: !!(login?.practice_id || currentPractice?.id) && !!activeQResult,
  });
  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "questionnaire_subs", objectId, {page: page, order: order, search: search}],
    queryFn: () =>
      fetch_all_with_count<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'questionnaire_result',
          fields: ['id', 'user', 'extra_data', 'completed_at', 'invited_at', 'mail_sent_at', 'is_locked', 'get_item'],
          sub_fields: {
            'user': ['id', 'full_name', 'email']
          },
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: search,
            advanced: {
              questionnaire_id: objectId
            }
          },
          practice_id: (login?.practice_id || currentPractice?.id)
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(login?.practice_id || currentPractice?.id) && !!objectId,
  });






  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {success?: boolean; url?: string; email?: string;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      objectsRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<QuestionnaireResult>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setEditing(false);
      setMainObject({
        questionnaire_id: objectId,
        practice_id: currentPractice?.id,
        do_invite: true
      });
      objectsRefetch();
    },
  });








  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 80 },
    { field: 'user_full_name', headerName: t("coach.models.questionnaire_results.user_full_name", "Naam"), width: 200, renderCell: params => <>
      <Typography sx={{lineHeight: '52px', fontSize: '0.9rem'}}>{params.row.user.full_name}</Typography>
    </> },
    { field: 'user_email', headerName: t("coach.models.questionnaire_results.user_email", "Email"), width: 200, renderCell: params => <>
      <Typography sx={{lineHeight: '52px', fontSize: '0.9rem'}}>{params.row.user.email}</Typography>
    </> },
    { field: 'invited_at', headerName: t("coach.models.questionnaire_results.invited_at", "Invited"), width: 250, renderCell: params => <>
      {!!params.row.invited_at && <Dt d={params.row.invited_at} f="DDD t" />}
    </> },
    { field: 'mail_sent_at', headerName: t("coach.models.questionnaire_results.mail_sent_at", "Last mailed"), width: 250, renderCell: params => <>
      {!!params.row.invited_at && <Dt d={params.row.mail_sent_at} f="DDD t" />} {!mutationCustomAction.isPending && <IconButton onClick={() => {
        mutationCustomAction.mutate({
          formData: {
            object: 'questionnaire_result',
            class_action: 'custom_api_send_invite',
            id: params.row.id,
          }
        });
      }}>
        <EmailIcon color="primary" />
      </IconButton>}
    </> },
    { field: 'completed_at', headerName: t("coach.models.questionnaire_results.completed_at", "Completed"), width: 250, renderCell: params => <>
      {!!params.row.completed_at && <Dt d={params.row.completed_at} f="DDD t" />} {!!params.row.is_locked && <LockIcon sx={{marginBottom: -1, color: 'rgba(0, 0, 0, 0.4)'}} />}
    </> },
  ];

  return <Page
    title={t("coach.menu.questionnaire_results", "Vragenlijstresultaten")}
    premiumGatekeeping={!!is_questionnaire}
    premiumMode='questionnaire'
    titleCrumbs={[
      {
        title: t("coach.menu.questionnaires", "Vragenlijsten"),
        path: "/questionnaires"
      }
    ]}
  >
    {!!editing && <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("general.questionnaire_results.section.invite")}</Typography>
          <Divider />
        </Grid>
        <ClientPicker
          backend
          clientId={mainObject.user_id}
          setClientId={(n) => {
            setMainObject({
              ...mainObject,
              user_id: n
            });
          }}
        />
        <Grid item xs={12} md={3}>
          <Switcher
            id="invite"
            caption={t("general.questionnaire_results.fields.invite_immediately")}
            value={!!mainObject?.do_invite}
            onChange={(c) => {
              setMainObject({
                ...mainObject,
                do_invite: !!c
              });
            }}
          />
        </Grid>






        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={!mainObject.user_id}
            contained
            onClick={(v) => {
              mutationSave.mutate({
                formData: {
                  object: 'questionnaire_result',
                  id: mainObject?.id,
                  ob: {
                    ...mainObject
                  }
                }
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              setEditing(false);
              setMainObject({
                questionnaire_id: objectId,
                practice_id: currentPractice?.id,
                do_invite: true
              });
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </>}






    {!editing && <>
      <SearchBox
        search={search}
        setSearch={setSearch}
        objectCount={count}
      />
      <DataGrid
        action_count={3}
        hideCount
        actions={(params) => {
          return (<>
            <IconButton title="View" disabled={!params.row.completed_at} color="default" onClick={() => {
              setQResultOpen(true);
              setActiveQResult(params.row.id);
            }}>
              <VisibilityIcon />
            </IconButton>
            <IconButton title="Open client" color="default" path={`/user/${params.row.user.id}/detail`}>
              <PersonSearchIcon />
            </IconButton>
            {!params.row.completed_at && <IconButton disabled={!!params.row.completed_at} title="Remove" destroyBtn color="default" onClick={() => {
              mutationDestroy.mutate({
                formData: {
                  object: 'questionnaire_result',
                  id: params.row.id,
                  ob: {
                    id: params.row.id,
                  }
                }
              });
            }}>
              <DeleteIcon />
            </IconButton>}
            {!!params.row.completed_at && (params.row.get_item || '').indexOf("missing.png") < 0 && <IconButton title="Download" color="default" onClick={() => {
              window.open(params.row.get_item, "_blank")
            }}>
              <DownloadIcon />
            </IconButton>}
          </>);
        }}
        objectCount={count}
        getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
        objects={(objects || []).map(u => ({
          ...u,
        }))}
        columns={columns}
        loader={objectsLoading}
        onSortModelChange={(a, b) => {
          let a_0_field:string = a[0].field;
          if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
          setOrder([a_0_field, a[0].sort].join(' '));
        }}
        page={page}
        setPage={(p) => {setPage(p);}}
      />

      <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
      <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

      {!!object && <CoachShowQuestionnaire
        open={qResultOpen}
        setOpen={setQResultOpen}
        object={object}
        setSavedOpen={(b) => {
          setSavedOpen(b);
          objectRefetch();
          objectsRefetch();
        }}
      />}

      <Fab
        onClick={() => {
          setEditing(true);
          setMainObject({
            questionnaire_id: objectId,
            practice_id: currentPractice?.id,
            do_invite: true
          });
        }}
      />
    </>}
  </Page>;
}
