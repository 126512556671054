import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../../lib/server_helper';
import { fetch_one, fetch_all } from "../../../../lib/v31lib";
import { loginAtom } from '../../../../lib/auth';
import { currentPracticeAtom } from '../../../../lib/practice';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from '@mui/material';

import {
  Button,
  Fab,
  FileDrop,
  FileUpload,
  Typography
} from '../../../../components/v2/styled';
import { AttachmentSimple } from '../../../../components/v2/AttachmentSimple';
import { SnackDestroyed } from '../../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../../components/v2/snacks/Saved';

import { User } from '../../../../models/User';

const { DateTime } = require("luxon");

type Props = {
  user: User;
}

export const CoachUserIntakeAdviceUploads: FunctionComponent<Props> = ({user}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  // const { state } = useLocation();

  const [editing, setEditing] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<{user_id: number; practice_id: number; item?: File}>({
    user_id: user?.id || 0,
    practice_id: currentPractice?.id || 0,
  });


  const {
    data: adviceUploads,
    isLoading: adviceUploadsLoading,
    isError: adviceUploadsError,
    isSuccess: adviceUploadsSuccess,
    refetch: adviceUploadsRefetch,
  } = useQuery({
    queryKey: ["advice_uploads", login?.id],
    queryFn: () =>
      fetch_all<{id: number, get_item: string, date: string, item_file_name: string, practice_name: string}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'advice_upload',
          fields: ['id', 'get_item', 'item_file_name', 'date', 'practice|id/name'],
          order: 'date DESC, created_at DESC',
          filter: {
            search: '',
            advanced: {
              user_id: user?.id,
              practice_id: currentPractice?.id
            }
          }
        },
        login
      ),
      // select: (d) => {
      //   return d.custom_result;
      // },
    enabled: !!login && !!currentPractice?.id,
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{user_id: number; practice_id: number; item?: File, get_item?: string;}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      adviceUploadsRefetch();
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<{user_id: number; practice_id: number; item?: File, get_item?: string;}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      setEditing(false);
      setMainObject({
        user_id: user?.id || 0,
        practice_id: currentPractice?.id || 0,
      });
      adviceUploadsRefetch();
    },
  });








  

  return <Grid container spacing={1}>
    {!!editing && <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.models.advice_uploads.section.basic")}</Typography>
          <Divider />
        </Grid>
        {/* <Grid item xs={12}>
          <FileUpload
            caption={t("coach.models.advice_uploads.item", "Bijlage")}
            fileInputName='item'
            selected={mainObject.item}
            onChange={(f) => {
              setMainObject({
                ...mainObject,
                item: f as File
              });
            }}
          />
        </Grid> */}
        <Grid item xs={12} sx={{marginTop: 2}}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advice_uploads.item", "Bijlage")}</Typography>
          <FileDrop
            onDrop={acceptedFiles => {
              setMainObject({
                ...mainObject,
                item: acceptedFiles[0] as File
              });
            }}
            multiple={false}
            maxSize={10 * 1000 * 1000}
            items={!!mainObject.item ? [mainObject.item] : []}
            dropText="Click/Drop"
            dropHint="All.: all files, < 10mb"
          />
          {/* {savedItems.length > 0 && <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.advice_uploads.item", "Bijlage")}</Typography>}
          {savedItems.map(item => <Tooltip title={<Box sx={{maxWidth: 300, maxHeight: 300}}><img src={item.get_item} style={{maxWidth: 280, maxHeight: 280, width: 'auto', height: 'auto'}} /></Box>}><Chip label={item.item_file_name} variant="outlined" color="primary" sx={{marginRight: 0.5}} onDelete={() => {setSavedItems(savedItems.filter(fff => fff.id !== item.id));}} /></Tooltip>)} */}
        </Grid>


        



        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: {
              xs: 'relative',
              md: 'sticky'
            },
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={!mainObject.item}
            contained
            onClick={(v) => {
              let formData = new FormData();
              formData.append('object', 'advice_upload');
              formData.append('handler_id', (login?.id || "").toString());
              formData.append('fields[]', 'id');
              // mainObject
              formData.append('ob[user_id]', mainObject?.user_id?.toString() || '');
              formData.append('ob[practice_id]', mainObject?.practice_id?.toString() || '');
              formData.append('ob[date]',DateTime.now().toFormat("dd/LL/y"));
              if (!!mainObject?.item) formData.append(`ob[item]`, mainObject?.item);

              mutationSave.mutate({
                formData: formData
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              setEditing(false);
              setMainObject({
                user_id: user?.id || 0,
                practice_id: currentPractice?.id || 0,
              });
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </>}
    {!editing && <>
      {!!adviceUploadsLoading && <Box sx={{textAlign: 'center'}}>
        <CircularProgress />  
      </Box>}
      {!!adviceUploadsSuccess && adviceUploads.length < 1 && <Box sx={{textAlign: 'center', width: '100%'}}>
        <Typography sx={{fontStyle: 'italic', fontSize: '0.7rem', color: theme.palette.secondary.main}}>{t("general.no_results_found")}</Typography> 
      </Box>}

      {adviceUploadsSuccess && adviceUploads.length > 0 && <Grid item xs={12} sx={{
        marginTop: 2,
        position: 'relative'
      }}>
        <Paper sx={{
          padding: 2
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {adviceUploads.map(attachment => <AttachmentSimple
                url={attachment.get_item || ''}
                file_name={attachment.item_file_name || ''}
                practice={attachment.practice_name}
                date={attachment.date}
                onDelete={() => {
                  mutationDestroy.mutate({
                    formData: {
                      object: 'advice_upload',
                      id: attachment.id,
                      ob: {
                        id: attachment.id,
                      }
                    }
                  });
                }}
              />)}
            </Grid>
          </Grid>
        </Paper>
      </Grid>}

      <Fab
        onClick={() => {
          setEditing(true);
          setMainObject({
            user_id: user?.id || 0,
            practice_id: currentPractice?.id || 0,
          });
        }}
      />
    </>}

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Grid>;
}

