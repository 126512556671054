import React, { FunctionComponent, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  Tooltip,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

import {
  Typography
} from '../styled';

import { EatMoment as EM } from '../../../models/EatMoment';

const { DateTime } = require("luxon");

type Props = {
  emoment: EM;
  isCoach?: boolean;
  onClick?: () => void;
  icon?: ReactElement;
};

export const EatMoment: FunctionComponent<Props> = ({emoment, isCoach, onClick, icon}) => {
  const theme = useTheme();
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);

  // const [actualType, setActualType] = useState<string>(type || 'text');

  let texts:(string|ReactElement)[] = [];
  if (!!emoment.given_entry) texts.push(emoment.given_entry);
  if (isCoach && !!emoment.entry_time_actual) {
    // if (texts.length > 0) texts.push(<br />);
    texts.push(`(${DateTime.fromISO(emoment.entry_time_actual).toFormat("cccc DDD HH:mm")})`);
  }
  (emoment.feedbacks || []).forEach((c) => {
    if (!isCoach || (!!isCoach && c.practice_id === currentPractice?.id)) {
      texts.push(<Typography sx={{
        fontSize: "0.8rem",
        marginLeft: 2,
        color: theme.palette.primary.main
      }}>{c.username}: {c.comment}</Typography>);
    }
  });

  let tup = false;
  (emoment.feedbacks || []).forEach((comm) => {
    if (!isCoach || (!!isCoach && comm.practice_id === currentPractice?.id)) {
      if (comm.thumb) tup = true;
    }
  });
  if (!tup) {
    (emoment.food_entries || []).forEach((fe) => {
      (fe.feedbacks || []).forEach((comm) => {
        if (comm.thumb) tup = true;
      });
    });
  }

  let fentries = false;
  (emoment.food_entries || []).forEach((fe) => {
    if (!!fe.get_item) fentries = true;
  });
//  texts.push(currentPractice?.name?.toString() || '');
  return (<Tooltip title={<Box sx={{padding: 1.5}}>{texts.map(tt => <Typography sx={{marginTop: 0.5, marginBottom: 0.5}}>{tt}</Typography>)}</Box>}>
    <Box
      sx={{
        border: `2px solid transparent`,
        borderColor: theme.palette.primary.main,
        borderRadius: 25,
        width: 30,
        height: 30,
        textAlign: 'center',
        fontSize: '1.1rem',
        margin: 0.5,
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative'
      }}
      onClick={(e) => {
        e.preventDefault();
        if (!!onClick) onClick();
      }}
    >
      {!icon && <CheckIcon />}
      {!!icon && icon}
      {/* <span className={`fa ${emoment.fa_key}`} /> */}
      {(emoment.feedbacks || []).filter(ff => (!isCoach || (!!isCoach && ff.practice_id === currentPractice?.id)) && ff.comment.length > 0).length > 0 && <span style={{
        position: 'absolute',
        top: -5,
        right: -5,
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        // "-webkit-text-stroke-width": '1px',
        // "-webkit-text-stroke-color": 'white'
      }} className={'fa fa-comment-dots'}></span>}
      {tup && <span style={{
        position: 'absolute',
        right: -5,
        bottom: -5,
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        // "-webkit-text-stroke-width": '1px',
        // "-webkit-text-stroke-color": 'white'
      }}className={'fa fa-thumbs-up'}></span>}
      {fentries && <span style={{
        position: 'absolute',
        top: -5,
        left: -5,
        fontSize: '0.8rem',
        color: theme.palette.primary.main,
        // "-webkit-text-stroke-width": '1px',
        // "-webkit-text-stroke-color": 'white'
      }} className={'fa fa-camera'}></span>}
    </Box>
  </Tooltip>);
}