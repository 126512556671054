import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  Box,
  Grid,
} from '@mui/material';

import {
  Button,
  Page,
  Typography,
} from '../../components/v2/styled';

import { JitsiMeeting } from '@jitsi/react-sdk';

import { Appointment } from '../../models/Appointment';

import HelpersVideoconferences from '../../actions/helpers/videoconferences';

const { Interval, DateTime } = require("luxon");

type Props = {
  coach?: boolean;
}

export const SharedMeeting: FunctionComponent<Props> = ({coach}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  // const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  // const queryClient = useQueryClient();
  const { state } = useLocation();
  const params = useParams();
  const [objectId, setObjectId] = useState<string | undefined>(params.id || state?.id);
  const [tick, setTick] = useState<number>(0);

  const [isBeforeMeeting, setIsBeforeMeeting] = useState<boolean>(false);
  const [isEarly, setIsEarly] = useState<boolean>(false);
  const [isLate, setIsLate] = useState<boolean>(false);
  const [minutesUntil, setMinutesUntil] = useState<number>(0);
  const [minutesUntilClose, setMinutesUntilClose] = useState<number>(0);
  const [showWarning, setShowWarning] = useState<boolean>(true);
  const [showWarningWaiting, setShowWarningWaiting] = useState<boolean>(true);
  const [showWarningMark1, setShowWarningMark1] = useState<boolean>(true);
  const [markReminder1, setMarkReminder1] = useState<boolean>(false);
  const [markReminder2, setMarkReminder2] = useState<boolean>(false);
  const [videoHidden, setVideoHidden] = useState<boolean>(false);

  // const [currentTab, setCurrentTab] = useState<number>(!!state && !!state.tab ? state.tab : 0);

  const {
    data: appointment,
    isLoading: appointmentLoading,
    isError: appointmentError,
    isSuccess: appointmentSuccess,
    refetch: appointmentRefetch,
  } = useQuery({
    queryKey: ["appointment", objectId],
    queryFn: () =>
      fetch_one<Appointment>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment',
          fields: ['id', 'can_meeting', 'user', 'meeting_id', 'start_time', 'end_time'],
          sub_fields: {
            'user': ['id', 'first_name', 'last_name']
          },
          id: objectId
        },
        login
      ),
    enabled: !!login && !!objectId,
  });







  useEffect(() => {
    if (!!state?.id) setObjectId(state?.id);
  }, [state?.id]);
  useEffect(() => {
    appointmentRefetch();
    let i = 0;

    const intervalId = setInterval(() => {
      i += 1;
      setTick(i);
    }, HelpersVideoconferences.check_interval())
  
    return () => clearInterval(intervalId);
  }, [objectId]);
  useEffect(() => {
    if (!!appointment) setTick(100);
  }, [appointment]);
  useEffect(() => {
    console.log('tick', tick);
    if (!!appointment) {
      setIsBeforeMeeting(DateTime.now() < DateTime.fromISO(appointment.start_time));
      setIsEarly(DateTime.now() < DateTime.fromISO(appointment.start_time).plus({minutes: -HelpersVideoconferences.time_before()}));
      setIsLate(DateTime.now() > DateTime.fromISO(appointment.end_time).plus({minutes: HelpersVideoconferences.time_after()}));

      setMinutesUntil(Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(appointment.start_time)).length('minutes'));
      setMinutesUntilClose(Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(appointment.end_time).plus({minutes: HelpersVideoconferences.time_after()})).length('minutes'));

      setMarkReminder1(DateTime.now() > DateTime.fromISO(appointment.end_time).plus({minutes: HelpersVideoconferences.mark_reminder1()}));
      setMarkReminder2(DateTime.now() > DateTime.fromISO(appointment.end_time).plus({minutes: HelpersVideoconferences.mark_reminder2()}));

      console.log(isBeforeMeeting, isEarly, isLate, minutesUntil, minutesUntilClose, markReminder1, markReminder2);
    }
  }, [tick]);

  let testing = HelpersVideoconferences.general_testing();
  let check_meeting_id = HelpersVideoconferences.general_check_meeting_id();
  let check_earlyness = HelpersVideoconferences.general_check_earlyness();
  check_earlyness=false;
  let check_lateness = HelpersVideoconferences.general_check_lateness();
  

  let title = t("meeting.title.generic", "Meeting");
  if (!!appointmentSuccess && !appointment) {
    title = t("meeting.title.error", "Ongeldige afspraak");
  } else {
    if (!!coach) {
      title = t("meeting.title.full", "Afspraak met {user}").replace("{user}", [appointment?.user?.first_name, appointment?.user?.last_name].join(" "));
    } else {
      title = t("meeting.title.generic", "Meeting");
    }
  }

  // @ts-ignore
  const handleAPI = (JitsiMeetAPI) => {
    // JitsiMeetAPI.executeCommand('subject', this.render_title());
    setTimeout(() => {
      JitsiMeetAPI.executeCommand('subject', title);
    }, 3000);
    // JitsiMeetAPI.addListener('readyToClose', (ob) => {
    //   this.setState({video_hidden: true});
    // });
  }

  return <Page sx={{padding: {xs: 0, md: 3}}} title={title}>
    {!!appointmentSuccess && <Grid container spacing={1}>

      {!appointment && <Grid item xs={12}>
        <Typography sx={{color: theme.palette.secondary.main}}>{t("meeting.error.invalid_appointment")}</Typography>  
      </Grid>}
      {!!appointment && <>
        {!!showWarning && <Grid item xs={12}>
          <Box>
            <Box sx={{
              width: '90%',
              border: `1px solid ${theme.palette.primary.main}`,
              padding: 2,
              textAlign: 'center',
              [theme.breakpoints.up('md')]: {
                width: "60%",
              },
              marginLeft: 'auto',
              marginRight: 'auto'
            }}>
              <Typography sx={{
                '& strong': {
                  fontWeight: 'bold'
                }
              }} html>{t("meeting.general.first_screen")}</Typography>
              <Button
                id="close_warning"
                onClick={() => {
                  setShowWarning(false);
                }}
                label={t("coach.general.actions.ok", "OK")}
              />
            </Box>
          </Box>
        </Grid>}
        {!showWarning && <>
          {!!check_meeting_id && !appointment.meeting_id && <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography sx={{
              fontStyle: 'italic',
              color: theme.palette.secondary.main,
              textAlign: 'center'
            }}>{t("meeting.error.invalid_id")}</Typography>
          </Grid>}
          {!(!!check_meeting_id && !appointment.meeting_id) && <Grid item xs={12}>
            {!!check_earlyness && isEarly && <Grid item xs={12} sx={{textAlign: 'center'}}>
              <Typography sx={{
                fontStyle: 'italic',
                color: theme.palette.secondary.main,
                textAlign: 'center'
              }}>{t("meeting.error.too_early")}</Typography>
            </Grid>}
            {!(!!check_earlyness && isEarly) && <Grid item xs={12}>
              {!!check_lateness && isLate && <Grid item xs={12} sx={{textAlign: 'center'}}>
                <Typography sx={{
                  fontStyle: 'italic',
                  color: theme.palette.secondary.main,
                  textAlign: 'center'
                }}>{t("meeting.error.too_late")}</Typography>
              </Grid>}
              {!(!!check_lateness && isLate) && <Grid item xs={12}>
                {!!testing && <Grid item xs={12} sx={{textAlign: 'center'}}>
                  testing, here's where the conference would come
                </Grid>}
                {!(!!testing) && <Grid item xs={12}>
                  <Box style={{position: 'relative'}}>
                    <Box sx={{
                      position: 'absolute',
                      top: 2.5,
                      left: 2.5,
                      paddingBottom: 3.5
                    }}>
                      {!!isBeforeMeeting && !!showWarningWaiting && <Box sx={{
                        padding: 2,
                        border: '1px solid transparent',
                        borderColor: theme.palette.warning.main,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                      }}>
                        <Typography sx={{
                          fontStyle: 'italic',
                          color: theme.palette.warning.main
                        }}>
                          {!!coach ? t("meeting.warning.waiting").replace('{minutes}', parseInt(minutesUntil.toString(), 10).toString()).replace('{name}', appointment?.user?.first_name || 'de cliënt') : t("meeting.warning.waiting_generic").replace('{minutes}', minutesUntil.toString())}
                          <Button
                            id="closeWarn"
                            sx={{
                              fontSize: '0.7rem',
                              marginLeft: 1
                            }} size="small"
                            contained
                            onClick={(e) => {
                              e.preventDefault();
                              setShowWarningWaiting(false);
                            }}
                            label={t("coach.general.actions.ok")}
                          />
                        </Typography>
                      </Box>}
                      {!!markReminder1 && !markReminder2 && !!showWarningMark1 && <Box sx={{
                        padding: 2,
                        border: '1px solid transparent',
                        borderColor: theme.palette.warning.main,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                      }}>
                        <Typography sx={{
                          fontStyle: 'italic',
                          color: theme.palette.warning.main
                        }}>
                          {t("meeting.warning.mark_reminder1").replace('{minutes}', parseInt(minutesUntilClose.toString(), 10).toString())}
                          <Button
                            id="closeWarn"
                            sx={{
                              fontSize: '0.7rem',
                              marginLeft: 1
                            }} size="small"
                            contained
                            onClick={(e) => {
                              e.preventDefault();
                              setShowWarningMark1(false);
                            }}
                            label={t("coach.general.actions.ok")}
                          />
                        </Typography>
                      </Box>}
                      {!!markReminder2 && <Box sx={{
                        padding: 2,
                        border: '1px solid transparent',
                        borderColor: theme.palette.warning.main,
                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                      }}>
                        <Typography sx={{
                          fontStyle: 'italic',
                          color: theme.palette.warning.main
                        }}>
                          {t("meeting.warning.mark_reminder2").replace('{minutes}', parseInt(minutesUntilClose.toString(), 10).toString())}
                        </Typography>
                      </Box>}
                    </Box>

                    {!videoHidden && <JitsiMeeting
                      domain={HelpersVideoconferences.domain()}
                      roomName={appointment.meeting_id || 'default'}
                      userInfo={{
                        displayName: (login?.first_name || 'User'),
                        email: ''
                      }}
                      onReadyToClose={() => {
                        setVideoHidden(true);
                      }}
                      getIFrameRef={(iframeRef) => {
                        iframeRef.style.height = '80vh';
                      }}
                      onApiReady={handleAPI}
                      // loadingComponent={() => <Box align='center'>
                      //   {t("meeting.loader")}<br /><br />
                      //   <CircularProgress />
                      // </Box>}
                      // containerStyle={{width: '100%'}}
                      // onAPILoad={handleAPI}
                      configOverwrite={{
                        disableDeepLinking: true,
                        prejoinConfig: {
                          enabled: false
                        }
                      }}
                    />}
                    {!!videoHidden && <Typography>{t("meeting.warning.meeting_ended")}</Typography>}
                  </Box>
                </Grid>}
              </Grid>}
            </Grid>}
          </Grid>}
        </>}
      </>}

    </Grid>}
  </Page>;
}

